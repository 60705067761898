/* eslint-disable */

import React from 'react';
import firebase from './firebase';

class AdminRegistration extends React.Component {

    render() {
        return (
            <div>
                <h3>Admin Registration</h3>

                <br />
                <hr />
                <br />
                <div className="col-lg-2"> </div>
                <div className="col-lg-8 login-form">
                    <div className="col-lg-8 login-form">
                        <form>
                            <div className="form-group">
                                <label className="form-control-label">Role</label>
                                <input id="newRole" type="text" className="form-control inputloginpage" />
                            </div>
                            <div className="form-group">
                                <label className="form-control-label">Expo Name</label>
                                <input id="expoName" type="text" className="form-control inputloginpage" />
                            </div>
                            <div className="form-group">
                                <label className="form-control-label">Project ID</label>
                                <input id="project" type="text" className="form-control inputloginpage" />
                            </div>
                            <div className="form-group">
                                <label className="form-control-label">EMAIL ID</label>
                                <input id="newEmail" type="email" className="form-control inputloginpage" />
                            </div>
                            <div className="form-group">
                                <label className="form-control-label">PASSWORD</label>
                                <input id="newPassword" type="password" className="form-control inputloginpage" />
                            </div>
                            <div id="UserAdded" style={{ display: 'none' }}>
                                <b>New user for analytics added successfully</b>
                            </div>
                            <div className="col-lg-12 loginbttm">
                                <div className="col-lg-12 login-btm login-button">
                                    <button id="reg-button" type="submit" className="registerbtn" onClick={this.onSubmitForm}>
                                        <div style={{ display: 'block' }} id="reg-name">Register</div>
                                        <div className="loaderloginbtn" id="btn-loader" style={{ display: 'none' }} />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-2"></div>

            </div>
        )
    }

    onSubmitForm = (e) => {
        e.preventDefault();

        //----LODER CALL---
        document.getElementById('reg-name').style.display = 'none';
        document.getElementById('btn-loader').style.display = 'block';
        document.getElementById('UserAdded').style.display = 'none';
        //--------------------

        let regEmail = document.getElementById('newEmail').value;
        const regPassword = document.getElementById('newPassword').value;
        let userRole = document.getElementById('newRole').value;
        let expoName = document.getElementById('expoName').value;
        expoName = expoName.toLowerCase();
        const projectId = document.getElementById('project').value;
        var patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
        regEmail = regEmail.toLowerCase();

        if (regEmail === "") {
            alert("Please enter a valid Email-id");
            //----LODER CALL---
            document.getElementById('reg-name').style.display = 'block';
            document.getElementById('btn-loader').style.display = 'none';
            //--------------------
        } else if (regPassword === "") {
            alert("Please enter a valid Password")
            //----LODER CALL---
            document.getElementById('reg-name').style.display = 'block';
            document.getElementById('btn-loader').style.display = 'none';
            //--------------------
        } else if (expoName === "") {
            alert("Please enter Expo Name")
            //----LODER CALL---
            document.getElementById('reg-name').style.display = 'block';
            document.getElementById('btn-loader').style.display = 'none';
            //--------------------
        } else {
            if (!patternEmail.test(regEmail)) {
                alert("Please enter a valid Email-id");
                //----LODER CALL---
                document.getElementById('reg-name').style.display = 'block';
                document.getElementById('btn-loader').style.display = 'none';
                //--------------------
            } else {
                let NewAuth = new Promise((resolve, reject) => {
                    firebase.auth().createUserWithEmailAndPassword(regEmail, regPassword)
                        .then(() => {
                            resolve('User Added');
                        })
                        .catch(function (error) {
                            // Handle Errors here.
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            reject(error);
                            // ...
                        });
                })

                if (userRole === 'user') {
                    if (projectId !== '') {
                        document.getElementById('reg-button').disabled = true;
                        NewAuth.then(result => {
                            if (result === 'User Added') {
                                this.RegisterOnDatabase();
                            }
                        }).catch(error => {
                            alert(error.message);
                            document.getElementById('reg-name').style.display = 'block';
                            document.getElementById('btn-loader').style.display = 'none';
                        });
                    } else {
                        alert('Please provide project id for user');
                        document.getElementById('reg-name').style.display = 'block';
                        document.getElementById('btn-loader').style.display = 'none';
                    }
                } else if (userRole === 'admin') {
                    document.getElementById('reg-button').disabled = true;
                    NewAuth.then(result => {
                        if (result === 'User Added') {
                            this.RegisterOnDatabase();
                        }
                    }).catch(error => {
                        alert(error.message);
                        document.getElementById('reg-name').style.display = 'block';
                        document.getElementById('btn-loader').style.display = 'none';
                    });
                } else {
                    alert('Please enter either admin or user in role');
                    document.getElementById('reg-name').style.display = 'block';
                    document.getElementById('btn-loader').style.display = 'none';
                }


            }
        }

    }

    RegisterOnDatabase = () => {
        let regEmail = document.getElementById('newEmail').value;
        regEmail = regEmail.toLowerCase();
        let expoName = document.getElementById('expoName').value;
        expoName = expoName.toLowerCase();
        const projectId = document.getElementById('project').value;
        let userRole = document.getElementById('newRole').value;

        if (userRole === 'admin') {
            const db = firebase.firestore();
            let NewDatabase = new Promise((resolve, reject) => {
                // Add a new document with a generated id.
                db.collection("AnalyticsUser").add({
                    email: regEmail,
                    expo: expoName,
                    role: userRole
                })
                    .then(function (docRef) {
                        console.log("Document written with ID: ", docRef.id);
                        resolve('User Added');
                    })
                    .catch(function (error) {
                        console.error("Error adding document: ", error);
                        reject(error);
                    });
            });
            NewDatabase.then(result => {
                if (result === 'User Added') {
                    document.getElementById('UserAdded').style.display = 'block';
                    document.getElementById('reg-button').removeAttribute('disabled');
                    document.getElementById('reg-name').style.display = 'block';
                    document.getElementById('btn-loader').style.display = 'none';
                }
            }).catch(error => {
                alert("Database entry error " + error);
            })
        } else {
            const db = firebase.firestore();
            let NewDatabase = new Promise((resolve, reject) => {
                // Add a new document with a generated id.
                db.collection("AnalyticsUser").add({
                    email: regEmail,
                    expo: expoName,
                    projectid: projectId,
                    role: userRole
                })
                    .then(function (docRef) {
                        console.log("Document written with ID: ", docRef.id);
                        resolve('User Added');
                    })
                    .catch(function (error) {
                        console.error("Error adding document: ", error);
                        reject(error);
                    });
            });
            NewDatabase.then(result => {
                if (result === 'User Added') {
                    document.getElementById('UserAdded').style.display = 'block';
                    document.getElementById('reg-button').removeAttribute('disabled');
                    document.getElementById('reg-name').style.display = 'block';
                    document.getElementById('btn-loader').style.display = 'none';
                }
            }).catch(error => {
                alert("Database entry error " + error);
            })
        }

    }
}

export default AdminRegistration;