/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from "react";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { MDBDataTable } from "mdbreact";

import "react-datepicker/dist/react-datepicker.css";
import firebase from "./firebase";
import { Form, FormControl, Button } from "react-bootstrap";
import { getCurrentPageAnalytics } from "./firebase-function";

let fromDate;
let toDate;
let toHHMMSS;
let typeValue;

export default class DisplayGallery extends React.Component {
  static data = [];
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data2: [],
      dataUser: [],
      startDateFrom: "",
      startDateTO: "",
      totalvisitor: "",
    };
    this.filterName = this.filterName.bind(this);
  }
  componentDidMount() {
    // let userrole = localStorage.getItem('user-role');
    // if (userrole === 'user') {
    //     this.get_total_visitor();
    // }

    getCurrentPageAnalytics("MEDEX2022LiveUsers", this.manageMyTable);
  }

  manageMyTable = (databaseEntries) => {
    console.log("DATA :: ", databaseEntries);
    let formattedData = [];
    for (let i = 0; i < databaseEntries.length; i++) {
      let endDate;
      if (databaseEntries[i].endtime === "") {
        let addSec = databaseEntries[i].starttime + 30000;
        let addedTime = new Date(addSec).toLocaleString("en-IN");
        endDate = addedTime;
      } else {
        endDate = new Date(databaseEntries[i].endtime).toLocaleString("en-IN");
      }

      let startDate;
      if (databaseEntries[i].starttime === "") {
        startDate = "-";
      } else {
        startDate = new Date(databaseEntries[i].starttime).toLocaleString(
          "en-IN"
        );
      }
      let mobNumber;
      if (databaseEntries[i].mobilenumber === "") {
        mobNumber = "-";
      } else {
        mobNumber = databaseEntries[i].mobilenumber;
      }

      let diff = Math.abs(
        new Date(databaseEntries[i].endtime) -
          new Date(databaseEntries[i].starttime)
      );
      let ms = diff % 1000;
      diff = (diff - ms) / 1000;
      let ss = diff % 60;
      diff = (diff - ss) / 60;
      let mm = diff % 60;
      diff = (diff - mm) / 60;
      let hh = diff % 24;
      let days = (diff - hh) / 24;

      if (hh < 10) {
        hh = "0" + hh;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      if (ss < 10) {
        ss = "0" + ss;
      }

      if (days === 0) {
        diff = hh + ":" + mm + ":" + ss;
      } else {
        diff = days + "/" + hh + ":" + mm + ":" + ss;
      }

      if (databaseEntries[i].endtime === "") {
        diff = "00:00:30";
      }

      let userType;
      if (diff <= "00:00:30") {
        userType = "Regular";
      } else {
        userType = "Active";
      }

      if (databaseEntries[i].type === 14) {
        typeValue = "Display-Gallary";
      }
      // if (databaseEntries[i].type === 4) {
      //     typeValue = 'Webinar-Hall'
      // } else if (databaseEntries[i].type === 3) {
      //     typeValue = 'Lobby'
      // } else if (databaseEntries[i].type === 17 || databaseEntries[i].type === 18) {
      //     typeValue = 'Lobby-lite'
      // } else if (databaseEntries[i].type === 15) {
      //     typeValue = 'Breakout-Room'
      // } else if (databaseEntries[i].type === 7) {
      //     typeValue = 'Chat-Room'
      // } else if (databaseEntries[i].type === 13) {
      //     typeValue = 'Archieved-Gallary'
      // } else if (databaseEntries[i].type === 14) {
      //     typeValue = 'Display-Gallary'
      // } else if (databaseEntries[i].type === 5) {
      //     typeValue = 'Entertainment-zone'
      // }

      let CompleteData = {
        id: databaseEntries[i]._id,
        fullname: databaseEntries[i].fullname,
        useremail: databaseEntries[i].useremail,
        mobilenumber: mobNumber,
        country: databaseEntries[i].country,
        // type: typeValue,

        // username: databaseEntries[i].username,
        // projectid: databaseEntries[i].projectid,
        // projectname: databaseEntries[i].projectname,
        starttime: startDate,
        endtime: endDate,
        duration: diff,
        usertype: userType,
        filterTime: databaseEntries[i].starttime,
        myfilterstartdate: new Date(
          databaseEntries[i].starttime
        ).toLocaleDateString(),
      };
      if (databaseEntries[i].type === 14) {
        formattedData.push(CompleteData);
      }
    }
    this.formatTable(formattedData);
  };

  formatTable = (myArrayData) => {
    var sortArray = myArrayData.sort(function (a, b) {
      return b.filterTime - a.filterTime;
    });
    let mydata = {
      columns: [
        {
          label: "User Name",
          field: "fullname",
          sort: "asc",
          width: 150,
        },
        {
          label: "Email",
          field: "useremail",
          sort: "asc",
          width: 150,
        },
        {
          label: "Mobile Number",
          field: "mobilenumber",
          sort: "asc",
          width: 150,
        },
        {
          label: "Country",
          field: "country",
          sort: "asc",
          width: 150,
        },

        {
          label: "Start-time",
          field: "starttime",
          sort: "asc",
          width: 150,
        },
        {
          label: "End-time",
          field: "endtime",
          sort: "asc",
          width: 150,
        },
        {
          label: "Duration",
          field: "duration",
          sort: "asc",
          width: 150,
        },
      ],
      rows: sortArray,
    };
    this.setState({ data: mydata, data2: mydata });
  };

  // get_total_visitor() {
  //     let currentComponent = this;
  //     const firestoreer = firebase.firestore();
  //     const docReff = firestoreer.collection("totalVisitor");
  //     docReff.get().then(function (querySnapshot) {
  //         querySnapshot.forEach(function (doc) {
  //             if (doc.data().expo === 'pinclick') {
  //                 const mydata = doc.data();
  //                 const incresCounter = mydata.counter;
  //                 currentComponent.setState({
  //                     totalvisitor: incresCounter
  //                 })
  //             }
  //         })
  //     })
  // }

  // manageMyTable = (databaseEntries) => {
  //     let formattedData = [];
  //     for (let i = 0; i < databaseEntries.length; i++) {
  //         let CompleteData = {
  //             mobilenumber: databaseEntries[i].mobilenumber,
  //             username: databaseEntries[i].username,
  //             starttime: databaseEntries[i].starttime,
  //         }
  //         formattedData.push(CompleteData);
  //     }
  //     this.get_users_list(formattedData);
  // }

  // get_users_list = (myArray) => {

  //     var result = [];
  //     myArray.reduce(function (res, value) {
  //         if (!res[value.mobilenumber]) {
  //             res[value.mobilenumber] = { mobilenumber: value.mobilenumber, username: value.username, starttime: 0 };
  //             result.push(res[value.mobilenumber])
  //         }
  //         res[value.mobilenumber].starttime += value.starttime;
  //         return res;
  //     }, {});

  //     let mydata = {
  //         columns: [
  //             {
  //                 label: 'Fullname',
  //                 field: 'username',
  //                 sort: 'asc',
  //                 width: 150
  //             },
  //             {
  //                 label: 'Mobileno',
  //                 field: 'mobilenumber',
  //                 sort: 'asc',
  //                 width: 150
  //             },

  //         ],
  //         rows: result,
  //     };
  //     this.setState({ data: mydata, data2: mydata });
  // }

  filterName(event) {
    const tempData = this.filterByValue(
      this.state.data.rows,
      event.target.value
    );
    let mydata = {
      columns: this.state.data.columns,
      rows: tempData,
    };
    this.setState({
      data2: mydata,
    });
  }
  filterByValue(array, value) {
    return array.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handleChange = (date) => {
    this.setState({
      startDateFrom: date,
    });
  };

  handleChangeTo = (date) => {
    this.setState({
      startDateTO: date,
    });
  };

  handleFilter = (e) => {
    e.preventDefault();
    fromDate = document.getElementById("fromDate").value;
    toDate = document.getElementById("toDate").value;
    // const  pikerDate = document.getElementById('datepicker').value;
    // console.log("pikerDate :: ",pikerDate)
    if (fromDate === "") {
      alert("Please enter From date");
    } else if (toDate === "") {
      alert("Please enter To date");
    } else {
      const tempData = this.filterByValueDate(this.state.data);
      this.setState({
        data2: tempData,
      });
    }
  };

  filterByValueDate(array) {
    return array.filter(
      (item) => item.starttime >= fromDate && item.starttime <= toDate
    );
  }

  handleCancle = (e) => {
    e.preventDefault();
    fromDate = document.getElementById("fromDate").value = "";
    toDate = document.getElementById("toDate").value = "";
    this.setState({
      startDateFrom: "",
      startDateTO: "",
    });
    this.componentDidMount();
  };

  render() {
    return (
      <div>
        <div className="flexheading">
          <h3 className="fontweight fontfamily">Analytics</h3>
          <div className="flexheading">
            {/* {localStorage.getItem('user-role') === 'user' &&
                            <label className="countlabel marginrightlabel"> Total Visitor :  <label className="numbers">{this.state.totalvisitor}</label></label>
                        } */}
            {/* <label className="countlabel marginrightlabel"> Count :  <label className="numbers">{this.state.data2.length}</label></label> */}
            <FormControl
              type="text"
              placeholder="search"
              onChange={(e) => this.filterName(e)}
              className="mr-sm-2 navbar-search-prop"
            />
          </div>
        </div>
        <div className="flexheading">
          <Form inline className=" marginbottomform">
            {/* <DatePicker
                            id="fromDate"
                            selected={this.state.startDateFrom}
                            onChange={this.handleChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From Date"
                            className="marginrightdate navbar-search-prop"
                        />
                        <DatePicker
                            id="toDate"
                            selected={this.state.startDateTO}
                            onChange={this.handleChangeTo}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="To Date"
                            className="marginrightdate navbar-search-prop"

                        />
                    
                        <div className="flexrow">
                            <button onClick={this.handleFilter} className="marginrightdate dashboardbtn">
                                Filter
                            </button>
                            <button onClick={this.handleCancle} className="marginrightdate dashboardbtn">
                                CANCEL
                            </button>
                        </div> */}
            <div>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button  downloadbtn"
                table="table-to-xls"
                filename="MEDEX 2022 DisplayGallery Visit"
                sheet="MEDEX 2022 DisplayGallery Visit"
                buttonText="Download as XLS"
              />
            </div>
          </Form>
        </div>
        <div className="overflowtableanalysis">
          <MDBDataTable
            searching={false}
            paging={true}
            data={this.state.data2}
          />
        </div>
        <div className="overflowtableanalysis" style={{ display: "none" }}>
          <table id="table-to-xls" className="table table-hover margintoptable">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">User Name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Country</th>
                <th scope="col">Start-time</th>
                <th scope="col">End-time</th>
                <th scope="col">Duration</th>
              </tr>
            </thead>
            {this.state.data2.rows && (
              <tbody>
                {Object.values(this.state.data2.rows).map((users, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{users.fullname}</td>
                      <td>{users.useremail}</td>
                      <td>{users.mobilenumber}</td>
                      <td>{users.country}</td>
                      <td>{users.starttime}</td>
                      <td>{users.endtime}</td>
                      <td>{users.duration}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  }
}
