/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from 'react';
import DatePicker from "react-datepicker";
import { MDBDataTable } from 'mdbreact';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


import "react-datepicker/dist/react-datepicker.css";
import firebase from './firebase';
import { Form, FormControl, Button } from 'react-bootstrap';

let fromDate;
let toDate;

const micrositeLinks = ['http://bit.ly/VR-AjmeraiLand',
    'https://bit.ly/Ajmera_Exotica_VR',
    'https://www.youtube.com/watch?v=L5-uVwj0LjQ&feature=youtu.be',
    'https://youtu.be/OaUCULxWi0g',
    'http://digiport.housing.com/digitour/projects/herohomes/herohomes.html',
    'https://my.matterport.com/show/?m=gRBr6J44ZSU',
    'https://my.matterport.com/show/?m=LhWSgpGoNZ4&brand=0',
    'https://www.palava.in/virtual-tour/',
    'https://youtu.be/1PvCqWSTw4Q',
    'http://tiny.cc/TE_ATR_V30',
    'http://tiny.cc/TMFT_exphome',
    'https://bit.ly/3dsG3fP',
    'https://bit.ly/306zw6x',
    'https://bit.ly/RG-vt'];

export default class ImageClick extends React.Component {
    static data = [];
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            startDateFrom: '',
            startDateTO: '',
            totalvisitor: '',
        }
        this.filterName = this.filterName.bind(this);
    }
    componentDidMount() {

        // let userrole = localStorage.getItem('user-role');
        // if (userrole === 'user') {
        //     this.get_total_visitor();
        // }

        this.get_users_list();
    }

    get_total_visitor() {
        let currentComponent = this;
        const firestoreer = firebase.firestore();
        const docReff = firestoreer.collection("totalVisitor");
        docReff.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                if (doc.data().expo === 'medex2022') {
                    const mydata = doc.data();
                    const incresCounter = mydata.counter;
                    currentComponent.setState({
                        totalvisitor: incresCounter
                    })
                }


            })

        })
    }

    get_users_list() {
        // e.preventDefault();
        let currentComponent = this;
        let userPid = localStorage.getItem('project-id');
        let userrole = localStorage.getItem('user-role');
        var firestore = firebase.firestore();
        let items = firestore.collection("ExpoimageClick");
        // items = items.where("date", ">=", 1596774600000)
        if (userrole !== 'admin') {
            items = items.where("projectid", "==", userPid)
        }
        items = items.where("expo", "==", "medex2022")
        let data = [];
        items.get().then(function (itemSnapshot) {
            itemSnapshot.forEach(function (docItem) {
                if ((docItem.data().mobilenumber !== '9375705497')
                    && (docItem.data().mobilenumber !== '9979981349')
                    && (docItem.data().mobilenumber !== '+917984604061')
                    && (docItem.data().mobilenumber !== '9016641369')
                    && (docItem.data().mobilenumber !== '9924302720')
                    && (docItem.data().mobilenumber !== '7383810472')
                    && (docItem.data().mobilenumber !== '1234567891')
                    && (docItem.data().mobilenumber !== '1234568791')
                    && (docItem.data().mobilenumber !== '7048853622')
                    && (docItem.data().mobilenumber !== '9662552666')
                    && (docItem.data().mobilenumber !== '7621901935')
                    && (docItem.data().mobilenumber !== '7984604061')
                    && (docItem.data().mobilenumber !== '9664777053')
                    && (docItem.data().mobilenumber !== '9016037231')
                ) {
                    var docData = docItem.data();
                    // console.log("DOC DATA :: ",docData);
                    docData._id = docItem.id;
                    // console.log("DOC DATA ID :: ",docData.currenttime);


                    let mobNumber;
                    if (docData.mobilenumber === "") {
                        mobNumber = "-"
                    } else {
                        mobNumber = docData.mobilenumber
                    }


                    let mydate;
                    if (docData.date === "") {
                        mydate = '-'
                    } else {
                        // mydate = new Date(docData.date).toLocaleDateString()
                        mydate = new Date(docData.date).toLocaleString('en-IN')
                    }

                    var value = docData.link;
                    let myLink;
                    if (value === undefined) {

                        if (docData.link === 'melzounite') {
                            myLink = 'Video call'
                        } else if (/^\d{10}$/.test(docData.link)) {
                            myLink = 'Audio call'
                        }

                    } else {

                        if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226150737001') {
                            myLink = 'Day 1 Prof. Andrew coats'
                        } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226155585001') {
                            myLink = 'Day 1 Prof. Alice cheng'
                        } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226161072001') {
                            myLink = 'Day 1 Prof. merlin thomas'
                        } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227754191001') {
                            myLink = 'Day 1 Q&A'
                        }


                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226167578001') {
                            myLink = 'Day 2 Prof. javed butler'
                        } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226171187001') {
                            myLink = 'Day 2 Prof. chantel mathiew'
                        } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226172298001') {
                            myLink = 'Day 2 Prof. per-henrik groop'
                        } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226951513001') {
                            myLink = 'Day 2 Q&A'
                        }


                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227744360001') {
                            myLink = 'Day 3'
                        }

                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227931922001') {
                            myLink = 'Day 4 Prof. Darren Maguire'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227941696001') {
                            myLink = 'Day 4 Prof. Melanie Davis'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227951470001') {
                            myLink = 'Day 4 Prof. Christoph Wanner'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227950910001') {
                            myLink = 'Day 4 Q&A'
                        }


                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227961487001') {
                            myLink = 'Day 5 Prof. Faiez Zannad'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227963439001') {
                            myLink = 'Day 5 Prof. Alice cheng'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227963287001') {
                            myLink = 'Day 5 Prof. Per-Henrik Groop'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227958619001') {
                            myLink = 'Day 5 Q&A'
                        }

                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229890850001') {
                            myLink = 'Day 6'
                        }


                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229893670001') {
                            myLink = 'Day 7 Prof. Melanie Davis'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229891932001') {
                            myLink = 'Day 7 Prof. Christoph Wanner'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229891830001') {
                            myLink = 'Day 7 Q&A'
                        }


                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229894529001') {
                            myLink = 'Day 8 Prof. Stefan Anker'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229891934001') {
                            myLink = 'Day 8 Prof. Peter Lin'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229894530001') {
                            myLink = 'Day 8 Prof. Merlin Thomas'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229891935001') {
                            myLink = 'Day 8 Q&A'
                        }



                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6231187226001') {
                            myLink = 'Day 9'
                        }




                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224010942001') {
                            myLink = "Improving Patient's Daily Activity"
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224018160001') {
                            myLink = 'Significance of HFpEF Treatment'
                        }

                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224021146001') {
                            myLink = 'Which is most important unmet need of HF patients'
                        }
                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224051877001') {
                            myLink = 'Importance of Functional Capacity Studies'
                        }

                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224059420001') {
                            myLink = 'What is more important to patients: Ooi or longevity'
                        }

                        else if (value === 'https://drive.google.com/file/d/11Ehhu8yKALuBYjHfK7bzIXGsZxZirkEH/view?usp=sharing') {
                            myLink = 'Heart Failure Program PDF'
                        }
                        else if (value === 'https://drive.google.com/file/d/1MiryvBi9VJClsKHmN3VZVwHskJTeRjcW/view?usp=sharing') {
                            myLink = 'Emperial - Stydu Design PDF'
                        }


                        else if (value === 'https://drive.google.com/file/d/1Vk2vAw41B_gl3i3JoyDZ9Oh2wJOaGSO_/view?usp=sharing') {
                            myLink = 'EMPEROR - Study Design PDF'
                        }

                        else if (value === 'https://drive.google.com/file/d/122UIzYvcdWdlEb-x6VzE0549vGi7xc_E/view?usp=sharing') {
                            myLink = 'EMPULSE - Study Design PDF'
                        }
                        else if (value === 'https://drive.google.com/file/d/1EfPiGLm-RLPREUXFxSH2PsEpSXPkNVTI/view?usp=sharing') {
                            myLink = 'EMPACT-MI static Infographic PDF'
                        }


                        else if (value === 'https://drive.google.com/file/d/1Ewobqs6r_63z_-mTql8GrF_hPfUt3bpB/view?usp=sharing') {
                            myLink = 'EMPAROR-Reduced KCCO sub-analysis Infographic PDF'
                        }


                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224755410001') {
                            myLink = 'Why is EMPA-KIDNEY important to do after the EMPA-REG OUTCOME trial?'
                        }

                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224758394001') {
                            myLink = 'can you diecribe how EMPA-KIDNEY is different from the other dedicated kidney trials with SGLT2I?'
                        }

                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224760422001') {
                            myLink = 'How could the results impact the way we treat patients with CKD?'
                        }


                        else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224762696001') {
                            myLink = 'Who is conducting the EMPA-KIDNEY study?'
                        }
                        // else if (value === '') {
                        //     myLink = ''
                        // }
                        else {
                            myLink = 'Microsite'
                        }
                    }


                    // console.log("Converted :: ",myLink)
                    if (docData.mobilenumber !== 'undefined' && docData.username !== 'undefined' && docData.mobilenumber !== 'null' && docData.username !== 'null') {
                        let CompleteData = {
                            id: docItem.id,
                            mobilenumber: mobNumber,
                            username: docData.username,
                            projectid: docData.projectid,
                            projectname: docData.projectname,
                            link: myLink,
                            date: mydate,
                            filterTime: docData.date,
                            myfilterstartdate: new Date(docData.date).toLocaleDateString()
                        }
                        data.push(CompleteData)
                    }
                }
            })
            // console.log("DATA ARRAY :: ",data)
            var sortArray = data.sort(function (a, b) { return b.filterTime - a.filterTime });

            let mydata = {
                columns: [
                    {
                        label: 'Mobile Number',
                        field: 'mobilenumber',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'User Name',
                        field: 'username',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Projectname',
                        field: 'projectname',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Link',
                        field: 'link',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Date',
                        field: 'date',
                        sort: 'asc',
                        width: 150
                    },

                ],
                rows: sortArray,
            };

            currentComponent.setState({ data: mydata, data2: mydata });
        })

        // } else {

        //     let items = firestore.collection("ExpoimageClick")
        //     items = items.where("projectid", "==", userPid)
        //     // items = items.where("date", ">=", 1596774600000)
        //     items = items.where("expo", "==", "medex2022")
        //     let data = [];
        //     items.get().then(function (itemSnapshot) {
        //         itemSnapshot.forEach(function (docItem) {
        //             var docData = docItem.data();
        //             console.log("DOC DATA :: ", docData);
        //             docData._id = docItem.id;
        //             // console.log("DOC DATA ID :: ",docData.currenttime);


        //             let mobNumber;
        //             if (mobNumber === "") {
        //                 mobNumber = "-"
        //             } else {
        //                 mobNumber = docData.mobilenumber
        //             }



        //             let mydate;
        //             if (docData.date === "") {
        //                 mydate = '-'
        //             } else {
        //                 // mydate = new Date(docData.date).toLocaleDateString()
        //                 mydate = new Date(docData.date).toLocaleString('en-IN')
        //             }

        //             var value = docData.link.split('/')[2];
        //             let myLink;
        //             if (value === undefined) {

        //                 if (docData.link === 'melzounite') {
        //                     myLink = 'Video call'
        //                 } else if (/^\d{10}$/.test(docData.link)) {
        //                     myLink = 'Audio call'
        //                 }

        //             } else {

        //                 if (value === 'drive.google.com') {
        //                     myLink = 'Download'
        //                 } else if (value === 'expo.virtualpropexpo2020.com') {
        //                     var micro = docData.link.split('/')[3];
        //                     // console.log("Micro :: ",micro)
        //                     myLink = 'Microsite-' + micro
        //                 } else if (value === 'wa.me') {
        //                     myLink = 'Whatsapp'
        //                 }

        //             }

        //             if (docData.mobilenumber !== 'undefined' && docData.username !== 'undefined' && docData.mobilenumber !== 'null' && docData.username !== 'null') {
        //                 let CompleteData = {
        //                     id: docItem.id,
        //                     mobilenumber: mobNumber,
        //                     username: docData.username,
        //                     projectid: docData.projectid,
        //                     projectname: docData.projectname,
        //                     link: myLink,
        //                     date: mydate,
        //                     filterTime: docData.date,
        //                     myfilterstartdate: new Date(docData.date).toLocaleDateString()
        //                 }
        //                 data.push(CompleteData)
        //             }
        //         })
        //         // console.log("DATA ARRAY :: ",data)
        //         var sortArray = data.sort(function (a, b) { return b.filterTime - a.filterTime });

        //         let mydata = {
        //             columns: [
        //                 {
        //                     label: 'Mobile Number',
        //                     field: 'mobilenumber',
        //                     sort: 'asc',
        //                     width: 150
        //                 },
        //                 {
        //                     label: 'User Name',
        //                     field: 'username',
        //                     sort: 'asc',
        //                     width: 150
        //                 },
        //                 {
        //                     label: 'Projectname',
        //                     field: 'projectname',
        //                     sort: 'asc',
        //                     width: 150
        //                 },
        //                 {
        //                     label: 'Link',
        //                     field: 'link',
        //                     sort: 'asc',
        //                     width: 150
        //                 },
        //                 {
        //                     label: 'Date',
        //                     field: 'date',
        //                     sort: 'asc',
        //                     width: 150
        //                 },

        //             ],
        //             rows: sortArray,
        //         };

        //         currentComponent.setState({ data: mydata, data2: mydata });
        //     })

        // }

    }
    filterName(event) {
        const tempData = this.filterByValue(this.state.data.rows, event.target.value);
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }
    filterByValue(array, value) {
        return array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    handleChange = date => {
        this.setState({
            startDateFrom: date
        });
    };

    handleChangeTo = date => {
        this.setState({
            startDateTO: date
        });
    };

    handleFilter = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value;
        toDate = document.getElementById('toDate').value;
        // const  pikerDate = document.getElementById('datepicker').value;
        // console.log("pikerDate :: ",pikerDate)
        if (fromDate === "") {
            alert("Please enter From date")
        } else if (toDate === "") {
            alert("Please enter To date")
        } else {
            const tempData = this.filterByValueDate(this.state.data);
            this.setState({
                data2: tempData
            });

        }
    }

    filterByValueDate(array) {

        // console.log("Date :: ", new Date(array[0].date).toLocaleDateString())
        // console.log("Date :: ",array[0].date)
        return array.filter((item) =>

            // item.date >= fromDate && item.date <= toDate
            item.myfilterstartdate >= fromDate && item.myfilterstartdate <= toDate

        );

    }

    handleCancle = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value = "";
        toDate = document.getElementById('toDate').value = "";
        this.setState({
            startDateFrom: '',
            startDateTO: ''
        })
        this.componentDidMount()
    }

    render() {
        return (
            <div>
                <div className="flexheading">
                    <h3 className="fontweight fontfamily">Call To Action</h3>
                    <div className="flexheading">

                        {/* {localStorage.getItem('user-role') === 'user' &&
                            <label className="countlabel marginrightlabel"> Total Visitor :  <label className="numbers">{this.state.totalvisitor}</label></label>
                        } */}

                        {/* <label className="countlabel marginrightlabel"> Count :  <label className="numbers">{this.state.data2.length}</label></label> */}

                        <FormControl type="text" placeholder="search" onChange={(e) => this.filterName(e)} className="mr-sm-2 navbar-search-prop" />
                    </div>
                </div>
                <div className="flexheading">

                    <Form inline className=" marginbottomform">
                        {/* <DatePicker
                            id="fromDate"
                            selected={this.state.startDateFrom}
                            onChange={this.handleChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From Date"
                            className="marginrightdate navbar-search-prop"
                        />
                        <DatePicker
                            id="toDate"
                            selected={this.state.startDateTO}
                            onChange={this.handleChangeTo}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="To Date"
                            className="marginrightdate navbar-search-prop"

                        /> */}

                        {/* <div className="flexrow">
                            <button onClick={this.handleFilter} className="marginrightdate dashboardbtn">
                                Filter
                            </button>
                            <button onClick={this.handleCancle} className="marginrightdate dashboardbtn">
                                CANCEL
                            </button>
                        </div> */}
                        <div>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button downloadbtn "
                                table="table-to-xls"
                                filename="ImageOnclick"
                                sheet="ImageOnclick"
                                buttonText="Download as XLS" />
                        </div>
                    </Form>
                </div>
                <div className="overflowtableanalysis">

                    <MDBDataTable
                        searching={false}
                        paging={true}
                        data={this.state.data2}
                    />


                </div>
                <div className="overflowtableanalysis" style={{ display: 'none' }}>
                    <table id="table-to-xls" className="table table-hover margintoptable">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Project Name</th>
                                <th scope="col">Link</th>
                                <th scope="col">Date</th>

                            </tr>
                        </thead>
                        {this.state.data2.rows &&
                            <tbody>
                                {Object.values(this.state.data2.rows).map((users, index) => {
                                    return (
                                        <tr key={users.id}>
                                            <td>{index + 1}</td>
                                            <td>{users.mobilenumber}</td>
                                            <td>{users.username}</td>
                                            <td>{users.projectname}</td>
                                            <td>{users.link}</td>
                                            <td>{users.date}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        )
    }
}