/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Switch, withRouter, BrowserRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AdminRegistration from "./AdminRegistration";
import Echarts from "./Echarts";
import MainDash from "./main-dash";

class RouteContainer extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <TransitionGroup>
        <CSSTransition
          key={this.props.location.key}
          timeout={{ enter: 600, exit: 600 }}
          classNames="fade"
          unmountOnExit
        >
          <Switch location={this.props.location}>
            <Route exact path="/" component={MainDash} />
            <Route path="/userlist" component={MainDash} />
            <Route path="/leaderboard" component={MainDash} />
            <Route path="/live" component={MainDash} />
            <Route path="/WebinarHall" component={MainDash} />
            <Route path="/WebinarHallExport" component={MainDash} />
            <Route path="/Lobbylite" component={MainDash} />
            <Route path="/BreakoutRoom" component={MainDash} />
            <Route path="/ChatRoom" component={MainDash} />
            <Route path="/DisplayGallery" component={MainDash} />
            <Route path="/ArchiveGallery" component={MainDash} />
            <Route path="/EntertainmentZone" component={MainDash} />
            <Route path="/VideoClick" component={MainDash} />
            <Route path="/imageclick" component={MainDash} />
            <Route path="/barchart" component={MainDash} />
            <Route path="/Lobby" component={MainDash} />
            <Route path="/adminreg" component={MainDash} />
            <Route path="/medexFaculty" component={MainDash} />
            <Route path="/display_Gallery" component={MainDash} />
            <Route path="/archive_Gallery" component={MainDash} />
            <Route path="/archiveExport" component={MainDash} />
            <Route path="/displayGallaryExport" component={MainDash} />
            <Route path="/combineExcel" component={MainDash} />
            <Route path="/combineExcel1" component={MainDash} />
            <Route path="/break-time-zone" component={MainDash} />
            <Route path="/dashboard-charts" component={MainDash} />
            <Route path="/analytics" component={MainDash} />
            <Route path="/e-charts" component={Echarts}></Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default withRouter(RouteContainer);
