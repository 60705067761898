/* eslint-disable */
import React, { Component } from "react";
// import "../common/css/dashboard/main.css";
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Card,
  CardContent,
  InputLabel,
} from "@material-ui/core";

import CryptoJS from "crypto-js";
import * as echarts from "echarts";
import Page from "./page";
import axios from "axios";
// import { UserContext } from "../Context";

// import { errorLogs } from "../UserLogs";
// import baseUrls from "../common/utils/base-urls";
import requestHeader from "./requestHeader";

let expoVisitData = [];
let adminMail = localStorage.getItem("adminMail");

let melaUserChart = null;
let melaProjectViewTop5Chart = null;
let melaProjectViewLeast5Chart = null;
let melaUserViews = null;
let melaUserViewsDaily = null;
let melaUserViewsDaily1 = null;
let melaUserViewsWeekly = null;
let melaUserViewsWeekly1 = null;
let melaUserViewsMonthly = null;
let melaUserViewsMonthly1 = null;
const baseUrls = {
  dynamoDbServerUrl: "https://rubber-board.ap-south-1.elasticbeanstalk.com/",
};

export default class AnalyticsCharts extends Component {
  //   static contextType = UserContext;
  constructor(props) {
    super(props);

    this.state = {
      melaUserChartData: [],
      melaProjectViewTop5: [],
      melaProjectViewLeast5: [],
      melaUserViewsChart: [],
      melaUserViewsLastMonthChart: [],
      melaUserViewsDailyChart: [],
      melaUserViewsWeeklyChart: [],
      melaUserViewsMonthlyChart: [],
      melaViewTop5Data: true,
      melaViewLeast5Data: true,
      melaActiveUserData: true,
      melaUserViewsChartData: false,
      melaUserViewsDailyChartData: true,
      melaUserViewsWeeklyChartData: true,
      melaUserViewsMonthlyChartData: true,
      melaUserViewsDailyChartCount: 0,
      melaUserViewsWeeklyChartCount: 0,
      melaUserViewsMonthlyChartCount: 0,

      expoList: [],
      expoName: "",
      userRole: "",
      severity: "",
      alertText: "",
      userUid: "",
      accounIconClick: false,
    };
  }

  componentDidMount() {
    console.log("hi");
    try {
      const self = this;
      self.setState({ userRole: self.context.userRole });

      self.setState({ userUid: "oFsuo9zZV3XQGh3pkdZf7AGr9Y23" });
      axios
        .post(
          `${baseUrls.dynamoDbServerUrl}rubber-board/stall/fetchExpo`,
          {
            uid: "1da49d4daf8aab4f9227",
            projectType: "Expo",
          },
          requestHeader.reqHeader
        )
        .then((encryptQuerySnapshot) => {
          console.log("encryptQuerySnapshot", encryptQuerySnapshot);
          let querySnapshotObj = CryptoJS.AES.decrypt(
            encryptQuerySnapshot.data.expo.replace(/[_]/g, "/"),
            "kjsn#ds;gefrjtgrddfhe)fmvd&swd3w"
          );
          let res = JSON.parse(querySnapshotObj.toString(CryptoJS.enc.Utf8));

          let promise = new Promise((resolve, rej) => {
            let expoData = [];
            res.map((expo) => {
              expoData.push({
                name: expo.expo,
                data: expo,
              });
            });
            resolve(expoData);
          });
          promise.then((data) => {
            self.setState(
              {
                expoList: data,
                expoName: data[0] ? data[0].name : "",
              },
              () => {
                self.initChart();
                // Mela Product graphs data initial setting
                if (true) {
                  self.state.melaActiveUserData && self.getMelaUserChartData();
                  self.state.melaViewTop5Data &&
                    self.getMelaProjectViewChartData();
                }
              }
            );
          });
        });
    } catch (err) {
      let email = adminMail;
      errorLogs("componentDidMount", err, "Dashboard", email);
    }
  }

  // Done Dynamo
  getMelaUserChartData() {
    try {
      // axios
      //   .post(`${baseUrls.serverUrl}mela/fetchstalls`, {
      //     expo: this.state.expoName,
      //     userRole: "user"
      //   }, requestHeader.reqHeader)
      axios
        .post(
          `${baseUrls.dynamoDbServerUrl}rubber-board/stall/fetchExpo`,
          {
            uid: "1da49d4daf8aab4f9227",
            projectType: "Stall",
          },
          requestHeader.reqHeader
        )
        .then((encryptQuerySnapshot) => {
          console.log("encryptQuerySnapshot", encryptQuerySnapshot);
          let querySnapshotObj = CryptoJS.AES.decrypt(
            encryptQuerySnapshot.data.expo.replace(/[_]/g, "/"),
            "kjsn#ds;gefrjtgrddfhe)fmvd&swd3w"
          );
          let res = JSON.parse(querySnapshotObj.toString(CryptoJS.enc.Utf8));

          let totalStalls = res.length;
          let activeUser = res.filter(
            (activeUser) => activeUser.isEnabled == true
          ).length;
          let inactiveStalls = totalStalls - activeUser;
          this.setState({
            melaUserChartData: [
              { value: inactiveStalls, name: "Inactive Stalls" },
              { value: activeUser, name: "Active Stalls" },
            ],
            melaActiveUserData: totalStalls > 0 ? true : false,
          });
          this.initChart();
          this.setMelaUserChartOption();
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      let email = adminMail;
      errorLogs("getMelaUserChartData", err, "Dashboard", email);
    }
  }
  setMelaUserChartOption() {
    try {
      melaUserChart.setOption({
        title: {
          text: "Mela User Count",
          subtext: "No. of Stall Owner",
          left: "left",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          bottom: "bottom",
        },
        toolbox: {
          orient: "horizontal",
          margin: "10px",
          itemSize: 12,
          showTitle: true,
          zlevel: 2,
          feature: {
            saveAsImage: {},
            dataView: {},
          },
        },

        series: [
          {
            name: "Mela user Stats",
            type: "pie",
            animationDuration: 2000,
            animationEasing: "quarticInOut",
            radius: [40, 80],
            avoidLabelOverlap: false,
            startAngle: 90,
            hoverOffset: 5,
            center: ["50%", "50%"],
            // roseType: "area",
            selectedMode: "multiple",
            data: this.state.melaUserChartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            emphasis: {
              label: {
                formatter: "{c} Stalls", // {c} data: [{value:},]
                show: true,
              },
            },
            emphasis: {
              labelLine: {
                show: true,
                smooth: false,
                length: 20,
                length2: 10,
              },
            },
          },
        ],
        calculable: true,
        color: ["#1E88E5", "#29B6F6", "#4DB6AC", "#00A5D0", "#1520A6"],
      });
    } catch (err) {
      let email = adminMail;
      errorLogs("setMelaUserChartOption", err, "Dashboard", email);
    }
  }

  // Done Dynamo
  getMelaProjectViewChartData() {
    try {
      const self = this;
      axios
        .post(
          `${baseUrls.dynamoDbServerUrl}viewer/fetchLogs`,
          {
            expo: this.state.expoName,
          },
          requestHeader.reqHeader
        )
        // 1491b7efb87ac19ec096
        .then((encryptQuerySnapshot) => {
          let querySnapshotObj = CryptoJS.AES.decrypt(
            encryptQuerySnapshot.data.replace(/[_]/g, "/"),
            "kjsn#ds;gefrjtgrddfhe)fmvd&swd3w"
          );
          let querySnapshot = JSON.parse(
            querySnapshotObj.toString(CryptoJS.enc.Utf8)
          );

          // console.log("FetchLogs in GRaph", querySnapshot);
          expoVisitData = [];
          querySnapshot.forEach((doc) => {
            const tempData = doc;
            expoVisitData.push({
              projectId: tempData.projectid ? tempData.projectid : "",
              projectName: tempData.projectname ? tempData.projectname : "",
              sceneName: tempData.scenename ? tempData.scenename : "",
              userName: tempData.username ? tempData.username : "",
              mobileNo: tempData.mobilenumber ? tempData.mobilenumber : 0,
              startTime: tempData.starttime ? tempData.starttime : 0,
              endtime: tempData.endtime ? tempData.endtime : 0,
            });
          });
          let timeTotal = 0;
          let TempArray = Object.entries(
            groupBy(expoVisitData, "projectName")
          ).map(
            (e) => (
              (timeTotal = 0),
              e[1].map((time) => {
                timeTotal = timeTotal + timeCount(time.startTime, time.endtime);
              }),
              {
                Project: e[1],
                Id: e[0],
                projectName: e[1][0].projectName,
                time: timeTotal,
              }
            )
          );
          function msToTime(duration) {
            var minutes = Math.floor(duration / (1000 * 60));
            return minutes;
          }
          let TempArray2 = TempArray;
          // Logic for Top5 Project

          TempArray.sort((a, b) => {
            return b.time - a.time;
          });
          TempArray = TempArray.slice(0, 5);

          let top5 = [];
          TempArray.map((data) => {
            top5.push({ value: msToTime(data.time), name: data.projectName });
          });

          // Logic for Least5 Project

          let least5 = [];
          if (TempArray.length > 5) {
            TempArray2.sort((a, b) => {
              return a.time - b.time;
            });
            TempArray2 = TempArray2.slice(0, 5);

            TempArray2.map((data) => {
              least5.push({
                value: msToTime(data.time),
                name: data.projectName,
              });
            });
          }
          self.setState({
            melaProjectViewTop5: top5,
            melaProjectViewLeast5: least5,
            melaViewTop5Data: top5.length > 0 ? true : false,
            melaViewLeast5Data: least5.length > 0 ? true : false,
          });

          self.initChart();
          self.setMelaProjectViewTop5ChartOption();
          self.setMelaProjectViewLeast5ChartOption();
        });

      const timeCount = (starttime, endtime) => {
        let diff = 0;
        if (endtime === "" || !endtime) {
          diff = 30436;
        } else {
          diff = Math.abs(new Date(endtime) - new Date(starttime));
        }
        return diff;
      };
    } catch (err) {
      let email = adminMail;
      errorLogs("getMelaProjectViewChartData", err, "Dashboard", email);
    }
  }
  setMelaProjectViewTop5ChartOption() {
    try {
      melaProjectViewTop5Chart.setOption({
        title: {
          text: "Most 5 Visited Project",
          subtext: "by total spent time (in Minutes)",
          left: "left",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          bottom: "bottom",
        },
        toolbox: {
          orient: "horizontal",
          margin: "10px",
          itemSize: 12,
          showTitle: true,
          zlevel: 2,
          feature: {
            saveAsImage: {},
            dataView: {},
          },
        },
        series: [
          {
            name: "Most 5 visited Project by total spent time",
            type: "pie",
            animationDuration: 2000,
            animationEasing: "quarticInOut",
            radius: [40, 80],
            avoidLabelOverlap: false,
            startAngle: 90,
            hoverOffset: 5,
            center: ["50%", "50%"],
            // roseType: "area",
            selectedMode: "multiple",
            data: this.state.melaProjectViewTop5,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            emphasis: {
              label: {
                show: true,
                formatter: "{c} Minutes", // {c} data: [{value:},]
              },
            },
            emphasis: {
              labelLine: {
                show: true,
                smooth: false,
                length: 20,
                length2: 10,
              },
            },
          },
        ],
        calculable: true,
        color: ["#1E88E5", "#29B6F6", "#4DB6AC", "#00A5D0", "#1520A6"],
      });
    } catch (err) {
      let email = adminMail;
      errorLogs("setMelaProjectViewTop5ChartOption", err, "Dashboard", email);
    }
  }
  setMelaProjectViewLeast5ChartOption() {
    try {
      melaProjectViewLeast5Chart.setOption({
        title: {
          text: "Least 5 Project",
          subtext: "by total spent time (in Minutes)",
          left: "left",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "horizontal",
          bottom: "bottom",
        },
        toolbox: {
          orient: "horizontal",
          margin: "10px",
          itemSize: 12,
          showTitle: true,
          zlevel: 2,
          feature: {
            saveAsImage: {},
            dataView: {},
          },
        },
        series: [
          {
            name: "Least 5 Project by total spent time",
            type: "pie",
            radius: "50%",
            data: this.state.melaProjectViewLeast5,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
        color: [
          "#69F0AE",
          "#00ACC1",
          "#B9F6CA",
          "#1DE9B6",
          "#1E88E5",
          "#29B6F6",
          "#4DB6AC",
          "#00A5D0",
          "#1520A6",
        ],
      });
    } catch (err) {
      let email = adminMail;
      errorLogs("setMelaProjectViewLeast5ChartOption", err, "Dashboard", email);
    }
  }

  initChart() {
    try {
      if (
        this.state.userRole == "SUPER_ADMIN" ||
        localStorage.getItem("product") == "Mela"
      ) {
        if (this.state.melaUserViewsChartData) {
          const melaUserViewsChartDom =
            document.getElementById("MelaUserViews");
          if (melaUserViews === null) {
            melaUserViews = echarts.init(melaUserViewsChartDom);
          }
        }
        if (this.state.melaUserViewsDailyChartData) {
          const melaUserViewsDailyChartDom =
            document.getElementById("MelaUserViewsDaily");
          if (melaUserViewsDaily === null) {
            melaUserViewsDaily = echarts.init(melaUserViewsDailyChartDom);
          }

          const melaUserViewsDaily1ChartDom = document.getElementById(
            "MelaUserViewsDaily1"
          );
          if (melaUserViewsDaily1 === null) {
            melaUserViewsDaily1 = echarts.init(melaUserViewsDaily1ChartDom);
          }
        }
        if (this.state.melaUserViewsWeeklyChartData) {
          const melaUserViewsWeeklyChartDom = document.getElementById(
            "MelaUserViewsWeekly"
          );
          if (melaUserViewsWeekly === null) {
            melaUserViewsWeekly = echarts.init(melaUserViewsWeeklyChartDom);
          }
          const melaUserViewsWeekly1ChartDom = document.getElementById(
            "MelaUserViewsWeekly1"
          );
          if (melaUserViewsWeekly1 === null) {
            melaUserViewsWeekly1 = echarts.init(melaUserViewsWeekly1ChartDom);
          }
        }
        if (this.state.melaUserViewsMonthlyChartData) {
          const melaUserViewsMonthlyChartDom = document.getElementById(
            "MelaUserViewsMonthly"
          );
          if (melaUserViewsMonthly === null) {
            melaUserViewsMonthly = echarts.init(melaUserViewsMonthlyChartDom);
          }

          const melaUserViewsMonthly1ChartDom = document.getElementById(
            "MelaUserViewsMonthly1"
          );
          if (melaUserViewsMonthly1 === null) {
            melaUserViewsMonthly1 = echarts.init(melaUserViewsMonthly1ChartDom);
          }
        }
      }

      if (
        this.state.userRole == "SUPER_ADMIN" ||
        localStorage.getItem("product") == "Mela"
      ) {
        if (this.state.melaActiveUserData) {
          const melaUserChartDom = document.getElementById("MelaUserCount");
          if (melaUserChart === null) {
            melaUserChart = echarts.init(melaUserChartDom);
          }
        }

        if (this.state.melaViewTop5Data) {
          const melaProjectViewTop5ChartDom = document.getElementById(
            "MelaProjectViewTop5"
          );
          if (melaProjectViewTop5Chart === null) {
            melaProjectViewTop5Chart = echarts.init(
              melaProjectViewTop5ChartDom
            );
          }
        }

        if (this.state.melaViewLeast5Data) {
          const melaProjectViewLeast5ChartDom = document.getElementById(
            "MelaProjectViewLeast5"
          );
          if (melaProjectViewLeast5Chart === null) {
            melaProjectViewLeast5Chart = echarts.init(
              melaProjectViewLeast5ChartDom
            );
          }
        }
      }
    } catch (err) {
      let email = localStorage.getItem("adminMail");
      errorLogs("initChart", err, "Dashboard", email);
    }
  }

  handleExpoChange = (e) => {
    try {
      this.setState(
        {
          expoName: e.target.value,
        },
        () => {
          this.getMelaProjectViewChartData();
          this.getMelaUserChartData();
        }
      );
    } catch (err) {
      let email = adminMail;
      errorLogs("handleExpoChange", err, "Dashboard", email);
    }
  };

  render() {
    return (
      <Page title="Dashboard">
        <Container maxWidth={false}>
          <Box display="flex">
            <Box flexGrow={1} id="dashboardHeading">
              <Typography variant="h4" className="admin-header">
                {this.state.userRole != "SUPER_ADMIN" &&
                  localStorage.getItem("product") == "Mela" &&
                  "Welcome to Rubber Board Analytics"}
              </Typography>
            </Box>
          </Box>
          <hr />

          <Grid container spacing={2} style={{ display: "none" }}>
            <Grid item md={2} xs={2}>
              <div className="card">
                <Card>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      marginTop: "25px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Daily Views
                  </Typography>
                  <div
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      top: "",
                      left: "50%",
                      fontSize: "30px",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {this.state.melaUserViewsDailyChartCount}
                  </div>
                  <div
                    id="MelaUserViewsDaily"
                    style={{
                      height: "111px",
                      width: "269px",
                      marginLeft: "-5px",
                    }}
                  />
                </Card>
              </div>
            </Grid>

            <Grid item md={2} xs={2}>
              <div className="card">
                <Card>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      marginTop: "25px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Weekly Views
                  </Typography>
                  <div
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      left: "50%",
                      fontSize: "30px",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {this.state.melaUserViewsWeeklyChartCount}
                  </div>
                  <div
                    id="MelaUserViewsWeekly"
                    style={{
                      height: "111px",
                      width: "269px",
                      marginLeft: "-5px",
                    }}
                  />
                </Card>
              </div>
            </Grid>

            <Grid item md={2} xs={2}>
              <div className="card">
                <Card>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      marginTop: "25px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Monthly Views
                  </Typography>
                  <div
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      left: "50%",
                      fontSize: "30px",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {this.state.melaUserViewsMonthlyChartCount}
                  </div>

                  <div
                    id="MelaUserViewsMonthly"
                    style={{
                      height: "111px",
                      width: "269px",
                      marginLeft: "-5px",
                    }}
                  />
                </Card>
              </div>
            </Grid>

            <Grid item md={2} xs={2} style={{ display: "none" }}>
              <div className="card">
                <Card>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      marginTop: "25px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Daily Views
                  </Typography>
                  <div
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      left: "50%",
                      fontSize: "30px",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {this.state.melaUserViewsDailyChartCount}
                  </div>
                  <div
                    id="MelaUserViewsDaily1"
                    style={{
                      height: "111px",
                      width: "269px",
                      marginLeft: "-5px",
                    }}
                  />
                </Card>
              </div>
            </Grid>
            <Grid item md={2} xs={2}>
              <div className="card">
                <Card>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      marginTop: "25px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Weekly Views
                  </Typography>
                  <div
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      left: "50%",
                      fontSize: "30px",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {this.state.melaUserViewsWeeklyChartCount}
                  </div>
                  <div
                    id="MelaUserViewsWeekly1"
                    style={{
                      height: "111px",
                      width: "269px",
                      marginLeft: "-5px",
                    }}
                  />
                </Card>
              </div>
            </Grid>

            <Grid item md={2} xs={2}>
              <div className="card">
                <Card>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      marginTop: "25px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Monthly Views
                  </Typography>
                  <div
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      left: "50%",
                      fontSize: "30px",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {this.state.melaUserViewsMonthlyChartCount}
                  </div>
                  <div
                    id="MelaUserViewsMonthly1"
                    style={{
                      height: "111px",
                      width: "269px",
                      marginLeft: "-5px",
                    }}
                  />
                </Card>
              </div>
            </Grid>
          </Grid>

          {(this.state.userRole == "SUPER_ADMIN" ||
            localStorage.getItem("product") == "Mela") && (
            <Card
              variant="elevation"
              elevation={5}
              style={{ marginTop: "20px" }}
            >
              <CardContent>
                <Box display="flex">
                  <Box
                    flexGrow={1}
                    style={{ width: "100%", margin: "0 8px 0 0" }}
                  >
                    <FormControl
                      style={{
                        width: "90%",
                        minWidth: 120,
                        margin: "0px",
                      }}
                      variant="outlined"
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        {" "}
                        Select Expo
                      </InputLabel>
                      <Select
                        value={this.state.expoName}
                        onChange={this.handleExpoChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        label=" Select Expo"
                      >
                        {this.state.expoList.map((expoName, index) => {
                          return (
                            <MenuItem value={expoName.name} key={index}>
                              {expoName.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    style={{ width: "80%" }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        color: "black",
                        margin: "auto",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {this.state.userRole == "SUPER_ADMIN" && (
                        <strong>Mela Analytics</strong>
                      )}
                      <br></br>
                      {!this.state.melaActiveUserData &&
                        !this.state.melaViewLeast5Data &&
                        !this.state.melaViewTop5Data &&
                        "Sorry currently no data available for analytics.!!"}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )}

          <div id="Userviewscard">
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Paper
                  variant="elevation"
                  className="basicpadding"
                  elevation={9}
                >
                  <div id="MelaUserViews" style={{ height: "300px" }} />
                </Paper>
              </Grid>
            </Grid>
          </div>

          {/* Note */}

          <div className="overflowdashboard" id="graphSection">
            {/* Mela Analytics */}

            <Typography
              variant="body1"
              style={{
                color: "black",
                margin: "auto",
                fontFamily: "Montserrat",
              }}
            >
              {this.state.userRole == "SUPER_ADMIN" && (
                <strong>Mela Analytics</strong>
              )}
            </Typography>

            {this.state.userRole == "SUPER_ADMIN" && <hr />}
            {/* User Count Pie Chart */}

            <Grid container spacing={3}>
              {(this.state.userRole == "SUPER_ADMIN" ||
                localStorage.getItem("product") == "Mela") &&
                this.state.melaActiveUserData && (
                  <Grid item md={6} xs={12}>
                    <Paper
                      variant="elevation"
                      className="basicpadding"
                      elevation={5}
                    >
                      <div id="MelaUserCount" style={{ height: "300px" }} />
                    </Paper>
                  </Grid>
                )}

              {/* Top 5 User's Project Pie chart */}

              {(this.state.userRole == "SUPER_ADMIN" ||
                localStorage.getItem("product") == "Mela") &&
                this.state.melaViewTop5Data && (
                  <Grid item md={6} xs={12}>
                    <Paper
                      variant="elevation"
                      className="basicpadding"
                      elevation={5}
                    >
                      <div
                        id="MelaProjectViewTop5"
                        style={{ height: "300px" }}
                      />
                    </Paper>
                  </Grid>
                )}

              {/* Least 5 User's Project Pie chart */}

              {(this.state.userRole == "SUPER_ADMIN" ||
                localStorage.getItem("product") == "Mela") &&
                this.state.melaViewLeast5Data && (
                  <Grid item md={6} xs={12}>
                    <Paper variant="elevation" className="basicpadding">
                      {/* Case Solved Chart */}
                      <div
                        id="MelaProjectViewLeast5"
                        style={{ height: "300px" }}
                      />
                    </Paper>
                  </Grid>
                )}
            </Grid>
          </div>
        </Container>
      </Page>
    );
  }
}

function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);

    return acc;
  }, []);
}
