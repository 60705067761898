import React, { Component } from "react";
import firebase from "./firebase";
import $ from "jquery";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import SendImg from "./assets/images/send.png";
import "./moderator.css";
import {
  loadAttendeeMessages,
  loadModeratorMessages,
  saveMessageToDatabaseforModerator,
} from "./chat-functions";
import { MDBDataTable } from "mdbreact";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Zoom,
  withStyles,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import axios from "axios";
import firebaseOther from "./firebasetest";



let eventName=""
let eventDate=""
let eventTime=""

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;

  return (
    <g>
      {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value.split(" ")[1]}
      </text>
    </g>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

export default class LiveUsersData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalLiveUsers: 0,
      websiteLiveUsers: 0,
      webinarHallLiveUsers: 0,
      exteriorLiveUsers: 0,
      lobbyLiveUsers: 0,
      displayGalleryLiveUsers: 0,
      archiveGalleryLiveUsers: 0,
      countryRoomLiveUsers: 0,
      facultyRoomLiveUsers: 0,
      breakTimeLiveUsers: 0,
      chartData: [],
      data2: [],
    };
    this.getActiveUsers = this.getActiveUsers.bind(this);
  }

  componentDidMount() {
    this.getActiveUsers("METABOLISM2022LiveUser", 1);
    // this.getWebinarActiveUsers("METABOLISM2022WebinarLiveUser");
    loadAttendeeMessages("metabolismWebinar");
    loadModeratorMessages("metabolismWebinar");
    $(function () {
      var arrow = $(".chat-head img");

      arrow.on("click", function () {
        var src = arrow.attr("src");

        $(".chat-body").slideToggle("fast");
        if (
          src ===
          "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
        ) {
          arrow.attr(
            "src",
            "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png"
          );
        } else {
          arrow.attr(
            "src",
            "https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png"
          );
        }
      });
    });
  }

  getEventData = () => {
    console.log("fetch flag data")
    let dbFlag = firebaseOther.firestore();
  
    dbFlag.collection("MetabolismFlag")
      .where("expo", "==", "metabolism")
      .onSnapshot(function (querySnapshot) {
        querySnapshot.docChanges().forEach(function(change){
          console.log("event name",change.doc.data())
          eventName=change.doc.data().eventName
          eventDate=change.doc.data().eventDate
          eventTime=change.doc.data().eventTime
        })
      })
  }

  /**
   *
   * @param {Collection Name to Check} collectionName
   * @param {Last Active User Threshold Time} timeInMin
   * This will give active users for current collection name for
   * respective time to asked for.
   */
  getActiveUsers(collectionName, timeInMin) {
    let currentTime = new Date().getTime();
    let timeToWatch = currentTime - timeInMin * 60000;

    let snapShotPromise = new Promise((resolve, reject) => {
      let liveQuery = firebase
        .firestore()
        .collection(collectionName)
        .where("endTime", ">=", timeToWatch);
      liveQuery.onSnapshot(function (querySnapshot) {
        let allData = [];

        querySnapshot.forEach(function (doc) {
          let currentData = doc.data();
          currentData.id = doc.id;
          allData.push(currentData);
        });
        resolve(allData);
        console.log("allData", allData);
      });
    });
    snapShotPromise.then((result) => {
      let existingData = [];
      console.warn("Successful", result);
      for (let i = 0; i < result.length; i++) {
        if (this.isNewUser(result[i].email, existingData)) {
          existingData.push(result[i]);
        }
      }
      this.setDataInChart(existingData);
    });
  }

  isNewUser = (userEmailToCheck, existingUsers) => {
    let status = true;
    for (let i = 0; i < existingUsers.length; i++) {
      if (userEmailToCheck === existingUsers[i].email) {
        status = false;
        break;
      }
    }
    return status;
  };

  setDataInChart = (finalData) => {
    console.warn("final data is ::", finalData);
    var allUsers = [],
      websiteUsers = [];
    var webinarUsers = [],
      lobbyUsers = [],
      exteriorUsers = [],
      breakTimeUsers = [],
      displayUsers = [],
      archiveUsers = [],
      countryUsers = [],
      facultyRoomUsers = [];
    finalData.forEach((item) => {
      if (item.roomName !== "website") {
        allUsers.push(item);

        if (item.roomName === "medex-faculty") {
          facultyRoomUsers.push(item);
        } else if (item.roomName === "webinarHall") {
          webinarUsers.push(item);
        } else if (item.roomName === "exterior") {
          exteriorUsers.push(item);
        } else if (item.roomName === "entrance") {
          lobbyUsers.push(item);
        } else if (item.roomName === "entertainmentZone") {
          breakTimeUsers.push(item);
        } else if (item.roomName.startsWith("archive")) {
          archiveUsers.push(item);
        } else if (item.roomName.startsWith("display")) {
          displayUsers.push(item);
        } else if (item.roomName.startsWith("country")) {
          countryUsers.push(item);
        }
      } else {
        websiteUsers.push(item);
      }
    });

    console.log("webinarUsers", webinarUsers);

    let data = [
      {
        name: "Total",
        Total: allUsers.length,
        webinarName: "Webinar",
        Webinar: webinarUsers.length,
        lobbyName: "Reception",
        Lobby: lobbyUsers.length,
        exteriorName: "Exterior",
        Exterior: exteriorUsers.length,
        facultyName: "Faculty",
        Faculty: facultyRoomUsers.length,
        displayName: "Display",
        Display: displayUsers.length,
        archiveName: "Archive",
        Archive: archiveUsers.length,
        countryName: "Country",
        Country: countryUsers.length,
        breakName: "Break Time",
        BreakTime: breakTimeUsers.length,
      },
    ];
    this.setState({
      chartData: data,
      totalLiveUsers: allUsers.length,
      websiteLiveUsers: websiteUsers.length,
      lobbyLiveUsers: lobbyUsers.length,
      breakTimeLiveUsers: breakTimeUsers.length,
      webinarHallLiveUsers: webinarUsers.length,
      exteriorLiveUsers: exteriorUsers.length,
      facultyRoomLiveUsers: facultyRoomUsers.length,
      displayGalleryLiveUsers: displayUsers.length,
      archiveGalleryLiveUsers: archiveUsers.length,
      countryRoomLiveUsers: countryUsers.length,
      data2: webinarUsers,
    });

    // this.formatTable(webinarUsers);
  };

  onUnload = (e) => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = "";
    console.log("on unload called");
  };

  sendMessage = (e) => {
    e.preventDefault();
    const msg = document.getElementById("message-txt").value;
    if (msg !== "") {
      // console.warn('Sending', msg);
      saveMessageToDatabaseforModerator(msg, "moderatorRoomMEDEX");
    } else {
      console.warn("Button should be disabled now");
    }
  };

  openchat = () => {
    document.getElementById("moderator").classList.add("bottomtotop");
    document.getElementById("CloseChat").classList.remove("hidebtn");
    document.getElementById("OpenChat").classList.add("hidebtn");
  };

  closechat = () => {
    document.getElementById("moderator").classList.remove("bottomtotop");
    document.getElementById("OpenChat").classList.remove("hidebtn");
    document.getElementById("CloseChat").classList.add("hidebtn");
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  sendEmail = (email,name) => {
      const regMessage =
      "Dear Dr./Prof. " +
      name +
      "," +
      "<br/>" +
      `<p><strong>${eventName}</strong> is live now you can attend the webinar by clicking on the below link. </p>

      <p>Join the event please visit <a href="http://metabolismandheartfailurescientifichub.com/">http://metabolismandheartfailurescientifichub.com/</a></p>
     <br/>
      <b>If you have any queries please reply to this mail.</b>
      <br/>
      <p>Thank you once again and have a wonderful day! </p>
      <br/>
      <p  style="font-size: 10px;">Disclaimer : This email message was sent to you because you have registered to attend METABOLLISM 2022 - Heart Failure Chapter.</p>
      <br/>`;
    var obj = {
      useremail: email,
      message: regMessage,
      SiteType: "metabolism",
      subject: "You are registered for METABOLISM 2022",
    };
    axios
      .post(
        "https://us-central1-sendtoemail-3610d.cloudfunctions.net/anglesSendemail",
        obj,
        {
          headers: {
          },
        }
      )
      .then(function () {
        alert("Email Send Successfully")
        // console.log("Done", tempEmail);
        // IncrementVisitorCounter();
      })
      .catch((error) => {
        console.log("Not DOne");
      });
  }

  render() {
    return (
      <div>
        <div>
          <br />
          Total : {this.state.totalLiveUsers}
          <br />
        </div>
        <div className="container-fluid">
          <div className="row marginchart">
            <div
              className="col-12 col-md-6 col-lg-6 marginchartcol"
              style={{ width: "400px", height: "300px" }}
            >
              <BarChart
                width={1000}
                height={350}
                data={this.state.chartData}
                // data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Total" fill="#CD6057" minPointSize={5}>
                  <LabelList dataKey="name" content={renderCustomizedLabel} />
                </Bar>
                <Bar dataKey="Webinar" fill="#AA6E9E" minPointSize={5}>
                  <LabelList
                    dataKey="webinarName"
                    content={renderCustomizedLabel}
                  />
                </Bar>
                <Bar dataKey="Lobby" fill="#3E8AA9" minPointSize={5}>
                  <LabelList
                    dataKey="lobbyName"
                    content={renderCustomizedLabel}
                  />
                </Bar>
                {/* <Bar dataKey="Exterior" fill="#2E916F" minPointSize={5} >
                                    <LabelList dataKey="exteriorName" content={renderCustomizedLabel} />
                                </Bar> */}
                {/* <Bar dataKey="Faculty" fill="#828637" minPointSize={5} >
                                    <LabelList dataKey="facultyName" content={renderCustomizedLabel} />
                                </Bar> */}
                <Bar dataKey="Display" fill="#B96F49" minPointSize={5}>
                  <LabelList
                    dataKey="displayName"
                    content={renderCustomizedLabel}
                  />
                </Bar>
                {/* <Bar dataKey="Archive" fill="#AA6E9E" minPointSize={5} >
                                    <LabelList dataKey="archiveName" content={renderCustomizedLabel} />
                                </Bar> */}
                {/* <Bar dataKey="Country" fill="#3E8AA9" minPointSize={5} >
                                    <LabelList dataKey="countryName" content={renderCustomizedLabel} />
                                </Bar> */}
                <Bar dataKey="BreakTime" fill="#B96F49" minPointSize={5}>
                  <LabelList
                    dataKey="breakName"
                    content={renderCustomizedLabel}
                  />
                </Bar>
              </BarChart>
            </div>
          </div>
        </div>
        <Box mt={3} mb={3}>
          {/* results  newsfeed list starts */}
          <Card>
            {/* <PerfectScrollbar> */}
            <Box md={12}>
              <div className="overflowtable">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>User Name</TableCell>
                      <TableCell>User Email</TableCell>
                      <TableCell>Mobile Number</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>Send Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.data2.map((feed, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{feed.name}</TableCell>
                        <TableCell>{feed.email}</TableCell>
                        <TableCell>{feed.mobileNumber}</TableCell>
                        <TableCell>{feed.country}</TableCell>
                        <TableCell>
                          {/* <LightTooltip
                            //   className={classes.tooltip}
                              TransitionComponent={Zoom}
                              title="Click to Edit Information"
                            > */}
                          <EmailIcon
                            onClick={() => this.sendEmail(feed.email,feed.name)}
                            variant="contained"
                            size="sm"
                            style={{cursor:"pointer"}}
                          />
                          {/* </LightTooltip> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Box>
          </Card>
        </Box>
      </div>
    );
  }
}
