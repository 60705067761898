/* eslint-disable no-unused-vars */
import React from "react";
import NavigationBar from "./components/navbar";
import UserList from "./userlist";
import WebinarHall from "./WebinarHall";
import Lobbylite from "./Lobbylite";
import BreakoutRoom from "./BreakoutRoom";
import DisplayGallery from "./DisplayGallery";
import ArchiveGallery from "./ArchiveGallery";
import EntertainmentZone from "./EntertainmentZone";
import VideoClick from "./VideoClick";
import ImageClick from "./ImageClick";
import ChartBar from "./ChartBar";
import Lobby from "./Lobby";
import ChatRoom from "./ChatRoom";
import Footer from "./components/footer";
import { Route } from "react-router-dom";
import "./assets/styles/main.css";
import MedexFaculty from "./medex-faculty";
import DisplayGallery1 from "./display-gallery";
import ArchiveGallery1 from "./archive-gallery";
import BreakTimeZone from "./break-time-zone";
import LiveUsers from "./liveUsers";
import ArchiveExport from "./archiveDataExport";
import DisplayGalleryExport from "./DisplayGallaryExport";
import WebinarHallExport from "./WebinarHallExport";
import CombineExcel from "./CombineExcel";
import CombineExcel1 from "./CombineExcel1";
import LiveUsersData from "./liveUsersData";
import UserListEmail from "./userlistEmail";
import AnalyticsCharts from "./AnalyticsCharts";
import Echarts from "./Echarts";

class Dashboard extends React.Component {
  render() {
    return (
      <>
        <div>
          <NavigationBar />
        </div>
        <div className="content">
          {/* {localStorage.getItem("user-role") === "admin" && ( */}
          <Route exact path="/" component={UserListEmail} />
          {/* )} */}
          {/* <Route path="/Lobby" component={Lobby} />
          {localStorage.getItem("user-role") !== "admin" && (
            <Route exact path="/" component={DisplayGallery} />
          )} */}
          {/* <Route path="/userlist" component={UserList} />
                    <Route path="/WebinarHall" component={WebinarHall} />
                    <Route path="/WebinarHallExport" component={WebinarHallExport} />
                    <Route path="/Lobbylite" component={Lobbylite} />
                    <Route path="/BreakoutRoom" component={BreakoutRoom} />
                    <Route path="/adminreg" component={Lobby} />
                    <Route path="/EntertainmentZone" component={EntertainmentZone} />
                    <Route path="/VideoClick" component={VideoClick} />
                    <Route path="/imageclick" component={ImageClick} />
                    <Route path="/medexFaculty" component={MedexFaculty} />
                    <Route path="/combineExcel" component={CombineExcel} />
                  <Route path="/combineExcel1" component={CombineExcel1} /> */}
          <Route path="/userlist" component={UserListEmail} />
          <Route path="/analytics" component={DisplayGallery1} />
          <Route path="/leaderboard" component={BreakTimeZone} />
          <Route path="/dashboard-charts" component={Echarts} />
          {/* <Route path="/live" component={LiveUsers} /> */}
          <Route path="/live" component={LiveUsersData} />
          
          {/* {localStorage.getItem('user-role') !== 'admin' &&
                        <Route path="/barchart" component={ChartBar} />
                    }
                    {localStorage.getItem('user-role') === 'admin' &&
                        <Route path="/ChatRoom" component={ChatRoom} />
                    } */}
        </div>
        <div className="footer-props">
          <Footer />
        </div>
      </>
    );
  }
}
export default Dashboard;
