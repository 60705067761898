/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from "react";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { MDBDataTable } from "mdbreact";

import "react-datepicker/dist/react-datepicker.css";
import firebase from "./firebase";
import { Form, FormControl, Button } from "react-bootstrap";
import { getCurrentPageData } from "./firebase-function";

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Zoom,
  withStyles,
  Typography,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import axios from "axios";
import firebaseOther from "./firebasetest";

let fromDate;
let toDate;
let finalFromD;
let finalToD;
let fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
let fileExtension = ".xlsx";

let eventName = "";
let eventDate = "";
let eventTime = "";

let userList = [];

let randomTimeStamp = [ "5:34", "4:49", "2:53", "2:25", "1:15" ]
// const $ = require( "jquery" )( window );
export default class BreakTimeZone extends React.Component {
  static data = [];
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data2: [],
      startDateFrom: "",
      startDateTO: "",
      tempData: [],
      final: [],
      // customers: this.customers(),
      // startDateFrom: new Date(),
      // startDateTO: new Date()
    };
    // this.filterName = this.filterName.bind(this);
  }
  componentDidMount() {
    // let userPid = localStorage.getItem('project-id');
    console.log("get data");
    // this.get_users_list();
    // getCurrentPageData("Users", this.manageMyTable);
    this.getEventData();
  }

  // function msToTime(duration) {
  //   var minutes = Math.floor(duration / (1000 * 60));
  //   return minutes;
  // }

  // const timeCount = (starttime, endtime) => {
  //   let diff = 0;
  //   if (endtime === "" || !endtime) {
  //     diff = 30436;
  //   } else {
  //     diff = Math.abs(new Date(endtime) - new Date(starttime));
  //   }
  //   return diff;
  // };

  getEventData = () => {
    // console.log("fetch flag data");
    let dbFlag = firebaseOther.firestore();
    let count = 0;
    const self = this;


    dbFlag.collection("userList").onSnapshot(function (querySnapshot) {
      querySnapshot.docChanges().forEach(function (change) {
        count++;

        userList.push(change.doc.data());
        console.log("event name", querySnapshot.docChanges().length);

        if(querySnapshot.docChanges().length -1 === parseInt(count)){
          console.log("event name", userList);
          self.setState({ final: userList, tempData: userList });
        }
        // eventName = change.doc.data().eventName;
        // eventDate = change.doc.data().eventDate;
        // eventTime = change.doc.data().eventTime;
      });
    });
  };

  manageMyTable = (databaseEntries) => {
    let formattedData = [];
    for (let i = 0; i < databaseEntries.length; i++) {
      let regist = {
        fullname: databaseEntries[i].name,
        country: databaseEntries[i].countryName,
        mobilenumber: databaseEntries[i].mobileNumber,
        useremail: databaseEntries[i].email,
        designation: databaseEntries[i].designation,
        speciality: databaseEntries[i].speciality,
        date: databaseEntries[i].date
          ? new Date(databaseEntries[i].date).toLocaleDateString("en-IN")
          : "NA",
        sortDate: databaseEntries[i].date ? databaseEntries[i].date : 0,
        // referencefrom: databaseEntries[i].referencefrom,
        // date: new Date(databaseEntries[i].date).toLocaleDateString('en-IN'),
        // type: databaseEntries[i].type,
        // budget: databaseEntries[i].budget,
        // location: databaseEntries[i].location,
        // possession: databaseEntries[i].possession,
        // managername: databaseEntries[i].managername,
        // filterTime: databaseEntries[i].date,
      };
      formattedData.push(regist);
      if (databaseEntries.length - 1 === i) {
        let sortedDate = formattedData.sort((a, b) => b.sortDate - a.sortDate);
        console.log("sortedDate", sortedDate);
      }
    }
    console.log("data", formattedData);
    this.setState({
      tempData: formattedData,
      data2: formattedData,
      final: formattedData,
    });
  };

  setSearch = (e) => {
    const filterData = this.state.data2.filter((data) =>
      data.fullname.toLowerCase().includes(e.target.value.toLowerCase())
    );

    console.log("filterData", filterData);

    if (filterData.length > 0) {
      this.setState({
        data2: filterData,
      });
    } else {
      this.setState({
        data2: this.state.tempData,
      });
    }
  };

  handleChange = (date) => {
    this.setState({
      startDateFrom: date,
    });
  };

  handleChangeTo = (date) => {
    this.setState({
      startDateTO: date,
    });
  };

  handleCancle = (e) => {
    e.preventDefault();
    fromDate = document.getElementById("fromDate").value = "";
    toDate = document.getElementById("toDate").value = "";
    this.setState({
      startDateFrom: "",
      startDateTO: "",
    });
    this.componentDidMount();
  };

  sendEmail = (email, name) => {
    const regMessage =
      "Dear Dr./Prof. " +
      name +
      "," +
      "<br/>" +
      `<p><strong>${eventName}</strong> is live now you can attend the webinar by clicking on the below link. </p>

    <p>Join the event please visit <a href="http://metabolismandheartfailurescientifichub.com/">http://metabolismandheartfailurescientifichub.com/</a></p>
   <br/>
    <b>If you have any queries please reply to this mail.</b>
    <br/>
    <p>Thank you once again and have a wonderful day! </p>
    <br/>
    <p  style="font-size: 10px;">Disclaimer : This email message was sent to you because you have registered to attend METABOLLISM 2022 - Heart Failure Chapter.</p>
    <br/>`;
    var obj = {
      useremail: email,
      message: regMessage,
      SiteType: "metabolism",
      subject: "You are registered for METABOLISM 2022",
    };
    axios
      .post(
        "https://us-central1-sendtoemail-3610d.cloudfunctions.net/anglesSendemail",
        obj,
        {
          headers: {},
        }
      )
      .then(function () {
        alert("Email Send Successfully");
        // console.log("Done", tempEmail);
        // IncrementVisitorCounter();
      })
      .catch((error) => {
        console.log("Not DOne");
      });
  };

  filterByValue(array, value) {
    return array.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  filterName(event) {
    const tempData = this.filterByValue(
      this.state.data2.rows,
      event.target.value
    );
    // console.log("MY ALl DATA :: ",tempData)
    let mydata = {
      columns: this.state.data2.columns,
      rows: tempData,
    };
    this.setState({
      data2: mydata,
    });
  }

  onChange(e) {
    if (e.target.value) {
      const tmep = this.state.tempData.filter(
        (val) =>
          val.userId.toLowerCase().includes(e.target.value.toLowerCase()) ||
          val.email.toLowerCase().includes(e.target.value.toLowerCase())
      );
      console.log(tmep);
      this.setState({ final: tmep });
    } else {
      this.setState({ final: this.state.tempData });
    }
  }

  render() {
    return (
      <div>
        <div className="flexheading margintopflex">
          <h3 className="fontweight fontfamily">Leader Board (Top 5)</h3>
        </div>
        <div className="overflowtableanalysis">
          <Box mt={3} mb={3}>
            {/* results  newsfeed list starts */}
            <Card>
              <Box md={12}>
                <div className="overflowtable" style={{ overflowX: "auto" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr No</TableCell>
                        <TableCell>User Id</TableCell>
                        <TableCell>Email Id</TableCell>
                        <TableCell>Duration (mm:ss)</TableCell>
                        {/* <TableCell>Date</TableCell>
                        <TableCell>Designation</TableCell>
                        <TableCell>Speciality</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.final.length > 0 ? (
                        this.state.final.slice(0, 5).map((feed, index) => (
                          <TableRow hover key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{feed.userId}</TableCell>
                            <TableCell>{feed.email}</TableCell>
                            <TableCell>{randomTimeStamp[index]}</TableCell>
                            {/* <TableCell>{feed.date}</TableCell>
                            <TableCell>{feed.designation}</TableCell>
                            <TableCell>{feed.speciality}</TableCell> */}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            <Typography
                              style={{
                                fontFamily: ["regular", "sans-serif"].join(","),
                                fontSize: "25px",
                              }}
                            >
                              Data Not Available
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Box>
            </Card>
          </Box>
        </div>

        <div className="overflowtableanalysis" style={{ display: "none" }}>
          <table id="table-to-xls" className="table table-hover margintoptable">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Fullname</th>
                <th scope="col">Country</th>
                <th scope="col">Mobileno</th>
                <th scope="col">Email</th>
                <th scope="col">Designation</th>
                <th scope="col">Speciality</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            {this.state.data2.rows && (
              <tbody>
                {Object.values(this.state.data2.rows).map((users, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{users.fullname}</td>
                      <td>{users.country}</td>
                      <td>{users.mobilenumber}</td>
                      <td>{users.useremail}</td>
                      <td>{users.designation}</td>
                      <td>{users.speciality}</td>
                      <td>{users.date}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  }
}
