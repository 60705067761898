import React from 'react';


export default class footer extends React.Component {
    render() {
        return (
            <>
                <ul className="footer-item-list">
                    <li>©Melzo. All Rights Reserved.</li>

                </ul>
                <ul className="mobile-footer-item">
                    <li>©Melzo. All Rights Reserved.</li>
                </ul>
            </>
        )
    }

}