/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';

import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './common/utils/utils';
import './common/css/color-codes.css';
import './assets/styles/index.css';
import { AuthProvider } from './AuthContext';

import RouteContainer from './route-container';

import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
// import WebFont from 'webfontloader';
// import Address from './Address';
// import Order from './order';
// import Revieworder from './revieworder';
// import Thanku from './thankupage'
// import Details from './orderdetails';

const history = createBrowserHistory();

const Index = () => (
  <AuthProvider>
    <Router history={history}>
      <RouteContainer />
    </Router>
  </AuthProvider>
);


render(<Index />, document.getElementById('root'));
// WebFont.load({

// google: {
//   families: ['Quicksand Sans', 'Quicksand']

//   }});



// ReactDOM.render(<App />, document.getElementById('root'));


// ReactDOM.render(<Address />, document.getElementById('root'));
// ReactDOM.render(<Order />, document.getElementById('root'));
// ReactDOM.render(<details />, document.getElementById('root'));
// ReactDOM.render(<Order />, document.getElementById('root'));
// ReactDOM.render(<Details />, document.getElementById('root'));
// ReactDOM.render(<Revieworder />, document.getElementById('root'));
// ReactDOM.render(<Thanku />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
