/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { MDBDataTable } from "mdbreact";
import "react-datepicker/dist/react-datepicker.css";
import { Form, FormControl, Button } from "react-bootstrap";
import { getCurrentPageAnalytics } from "./firebase-function";
import firebaseOther from "./firebasetest";
let fromDate;
let toDate;
let toHHMMSS;
let typeValue;

export default class DisplayGallery1 extends React.Component {
  static data = [];
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data2: [],
      dataUser: [],
      startDateFrom: "",
      startDateTO: "",
      totalvisitor: "",
      final:[],
      tempData:[]
    };
    // this.filterName = this.filterName.bind(this);
    this.getEventData=this.getEventData.bind(this);
    
  }
  componentDidMount() {
    // let userrole = localStorage.getItem('user-role');
    // if (userrole === 'user') {
    //     this.get_total_visitor();
    // }

    // getCurrentPageAnalytics("metaverseEvent", this.manageMyTable);
    this.getEventData();
  }
  componentDidUpdate(){
    
  }
  getEventData = () => {
    // console.log("fetch flag data");
    let dbFlag = firebaseOther.firestore();
    let userList=[];
    dbFlag.collection("metaverseEvent").get()
    .then(querySnapshot=>{
      querySnapshot.forEach(doc=>{
        console.log(doc.data()+"MetaVerse");
        userList.push(doc.data());
        
      })

      this.setState({final:userList});
      this.setState({tempData:userList});
    })
    // .onSnapshot(function (querySnapshot) {
    //   querySnapshot.docChanges().forEach(function (change) {
    //     console.log("event name", change.doc.data());
    //     userList.push(change.doc.data());
    //     console.log(JSON.stringify(change.doc.data())+"metaverseEvent");
    //     console.log(userList+"UserList");
    //     eventName = change.doc.data().eventName;
    //     eventDate = change.doc.data().eventDate;
    //     eventTime = change.doc.data().eventTime;
    //     this.setState({ final: userList });
    //   });
    // });
    // console.log("UserList");
    // console.log(JSON.stringify(userList)+"userList");
    // this.setState({ final: userList });
  };
  manageMyTable = (databaseEntries) => {
    console.log("DATA :: ", databaseEntries);
    let formattedData = [];
    for (let i = 0; i < databaseEntries.length; i++) {
      let endDate;
      if (databaseEntries[i].endTime === "") {
        let addSec = databaseEntries[i].startTime + 30000;
        let addedTime = new Date(addSec).toLocaleString("en-IN");
        endDate = addedTime;
      } else {
        endDate = new Date(databaseEntries[i].endTime).toLocaleString("en-IN");
      }

      let startDate;
      if (databaseEntries[i].startTime === "") {
        startDate = "-";
      } else {
        startDate = new Date(databaseEntries[i].startTime).toLocaleString(
          "en-IN"
        );
      }
      let mobNumber;
      if (databaseEntries[i].mobileNumber === "") {
        mobNumber = "-";
      } else {
        mobNumber = databaseEntries[i].mobileNumber;
      }

      let diff = Math.abs(
        new Date(databaseEntries[i].endTime) -
          new Date(databaseEntries[i].startTime)
      );
      let ms = diff % 1000;
      diff = (diff - ms) / 1000;
      let ss = diff % 60;
      diff = (diff - ss) / 60;
      let mm = diff % 60;
      diff = (diff - mm) / 60;
      let hh = diff % 24;
      let days = (diff - hh) / 24;

      if (hh < 10) {
        hh = "0" + hh;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      if (ss < 10) {
        ss = "0" + ss;
      }

      if (days === 0) {
        diff = hh + ":" + mm + ":" + ss;
      } else {
        diff = days + "/" + hh + ":" + mm + ":" + ss;
      }

      if (databaseEntries[i].endTime === "") {
        diff = "00:00:30";
      }

      let userType;
      if (diff <= "00:00:30") {
        userType = "Regular";
      } else {
        userType = "Active";
      }

      if (databaseEntries[i].roomName === "display") {
        typeValue = "Display Gallery";
      }
      // if (databaseEntries[i].type === 4) {
      //     typeValue = 'Webinar-Hall'
      // } else if (databaseEntries[i].type === 3) {
      //     typeValue = 'Lobby'
      // } else if (databaseEntries[i].type === 17 || databaseEntries[i].type === 18) {
      //     typeValue = 'Lobby-lite'
      // } else if (databaseEntries[i].type === 15) {
      //     typeValue = 'Breakout-Room'
      // } else if (databaseEntries[i].type === 7) {
      //     typeValue = 'Chat-Room'
      // } else if (databaseEntries[i].type === 13) {
      //     typeValue = 'Archieved-Gallary'
      // } else if (databaseEntries[i].type === 14) {
      //     typeValue = 'Display-Gallary'
      // } else if (databaseEntries[i].type === 5) {
      //     typeValue = 'Entertainment-zone'
      // }

      let CompleteData = {
        id: databaseEntries[i]._id,
        fullname: databaseEntries[i].name,
        useremail: databaseEntries[i].email,
        mobilenumber: mobNumber,
        // country: databaseEntries[i].country,
        country: databaseEntries[i].country,
        designation: databaseEntries[i].designation,
        speciality: databaseEntries[i].speciality,
        // type: typeValue,

        // username: databaseEntries[i].username,
        // projectid: databaseEntries[i].projectid,
        // projectname: databaseEntries[i].projectname,
        starttime: startDate,
        endtime: endDate,
        duration: diff,
        usertype: userType,
        filterTime: databaseEntries[i].startTime,
        myfilterstartdate: new Date(
          databaseEntries[i].startTime
        ).toLocaleDateString(),
      };

      if (databaseEntries[i].roomName === "display") {
        formattedData.push(CompleteData);
      }
    }
    this.formatTable(formattedData);
  };

  formatTable = (myArrayData) => {
    // var sortArray = myArrayData.sort(function (a, b) {
    //   return b.filterTime - a.filterTime;
    // });
    let mydata = {
      columns: [
        {
          label: "E-mail",
          field: "email",
          sort: "asc",
          width: 150,
        },
        {
          label: "User-Id",
          field: "userId",
          sort: "asc",
          width: 150,
        },
        {
          label: "Meta-Tag",
          field: "metaTag",
          sort: "asc",
          width: 150,
        },
        {
          label: "Event",
          field: "event",
          sort: "asc",
          width: 150,
        },

        // {
        //   label: "Start-time",
        //   field: "starttime",
        //   sort: "asc",
        //   width: 150,
        // },
        // {
        //   label: "End-time",
        //   field: "endtime",
        //   sort: "asc",
        //   width: 150,
        // },
        // {
        //   label: "Duration",
        //   field: "duration",
        //   sort: "asc",
        //   width: 150,
        // },
      ],
      rows: sortArray,
    };
    this.setState({ data: mydata, data2: mydata });
  };

  // filterName(event) {
  //   const tempData = this.filterByValue(
  //     this.state.data.rows,
  //     event.target.value
  //   );
  //   let mydata = {
  //     columns: this.state.data.columns,
  //     rows: tempData,
  //   };
  //   this.setState({
  //     data2: mydata,
  //   });
  // }
  // filterByValue(array, value) {
  //   return array.filter(
  //     (data) =>
  //       JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
  //   );
  // }
  onChange(e) {
    if (e.target.value) {
      console.log(JSON.stringify(this.state.tempData)+"TempData");
      let tmep = this.state.tempData.filter(
        (val) =>
        (
          val.userId != null && val.email != null
        )
      
          
      );
console.log(JSON.stringify(tmep)+"temp1");

      tmep=tmep.filter(
        (val)=>(
          val.event.toLowerCase().includes(e.target.value.toLowerCase()) || val.userId.toLowerCase().includes(e.target.value.toLowerCase()) ||
          val.email.toLowerCase().includes(e.target.value.toLowerCase())
        )
      )
      console.log(JSON.stringify(tmep)+"temp2");
      this.setState({ final: tmep });
    } else {
      this.setState({ final: this.state.tempData });
    }
  }
  handleChange = (date) => {
    this.setState({
      startDateFrom: date,
    });
  };

  handleChangeTo = (date) => {
    this.setState({
      startDateTO: date,
    });
  };

  handleFilter = (e) => {
    e.preventDefault();
    fromDate = document.getElementById("fromDate").value;
    toDate = document.getElementById("toDate").value;
    // const  pikerDate = document.getElementById('datepicker').value;
    // console.log("pikerDate :: ",pikerDate)
    if (fromDate === "") {
      alert("Please enter From date");
    } else if (toDate === "") {
      alert("Please enter To date");
    } else {
      const tempData = this.filterByValueDate(this.state.data);
      this.setState({
        data2: tempData,
      });
    }
  };

  filterByValueDate(array) {
    return array.filter(
      (item) => item.starttime >= fromDate && item.starttime <= toDate
    );
  }

  handleCancle = (e) => {
    e.preventDefault();
    fromDate = document.getElementById("fromDate").value = "";
    toDate = document.getElementById("toDate").value = "";
    this.setState({
      startDateFrom: "",
      startDateTO: "",
    });
    this.componentDidMount();
  };

  render() {
    return (
      <div>
        <div className="flexheading">
          <h3 className="fontweight fontfamily">Analytics</h3>
          <div className="flexheading">
            <FormControl
              type="text"
              placeholder="search"
              onChange={(e) => this.onChange(e)}
              className="mr-sm-2 navbar-search-prop"
            />
          </div>
        </div>
        <div className="flexheading">
          <Form inline className=" marginbottomform">
            <div>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button  downloadbtn"
                table="table-to-xls"
                filename="MetaVerse Analytics"
                sheet="MetaVerse Analytics"
                buttonText="Download as XLS"
              />
            </div>
          </Form>
        </div>
        <div className="overflowtableanalysis">
          {/* <MDBDataTable
            searching={false}
            paging={true}
            data={this.state.data2}
          /> */}
          <table id="table-to-xls" className="table table-hover margintoptable">
            <thead>
              <tr>
              <th scope="col">No.</th>
                <th scope="col">Event</th>
                {/* <th scope="col">Meta-Tag</th> */}
                <th scope="col">E-mail</th>
                <th scope="col">User-Id</th>
                {/* <th scope="col">Country</th>
                <th scope="col">Designation</th>
                <th scope="col">Speciality</th>
                <th scope="col">Start-time</th>
                <th scope="col">End-time</th>
                <th scope="col">Duration</th> */}
              </tr>
            </thead>
            {/* {this.state.final && ( */}
              <tbody>
                {this.state.final.map((users, index) => {
                  console.log("userss"+users.email);
                  console.log(JSON.stringify(this.state.final)+"final");
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{users.event}</td>
                      {/* <td>{users.metaTag}</td> */}
                      <td>{users.email}</td>
                      <td>{users.userId}</td>
                      {/* <td>{users.designation}</td>
                      <td>{users.speciality}</td>
                      <td>{users.starttime}</td>
                      <td>{users.endtime}</td>
                      <td>{users.duration}</td> */}
                    </tr>
                  );
                })}
                
              </tbody>
            {/* )} */}
          </table>
        </div>
        <div className="overflowtableanalysis"  >
          <table id="table-to-xls" className="table table-hover margintoptable">
            <thead>
              <tr>
              <th scope="col">No.</th>
                <th scope="col">Event</th>
                <th scope="col">Meta-Tag</th>
                <th scope="col">E-mail</th>
                <th scope="col">User-Id</th>
                {/* <th scope="col">Country</th>
                <th scope="col">Designation</th>
                <th scope="col">Speciality</th>
                <th scope="col">Start-time</th>
                <th scope="col">End-time</th>
                <th scope="col">Duration</th> */}
              </tr>
            </thead>
            {this.state.data.row && (
              <tbody>
                {this.state.final.map((users, index) => {
                  console.log("Userss"+users);
          
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{users.event}</td>
                      <td>{users.metaTag}</td>
                      <td>{users.email}</td>
                      <td>{users.userId}</td>
                      {/* <td>{users.designation}</td>
                      <td>{users.speciality}</td>
                      <td>{users.starttime}</td>
                      <td>{users.endtime}</td>
                      <td>{users.duration}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  }
}
