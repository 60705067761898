import firebase from "firebase";
let firebaseOther;
SetupFirebase();

/**
 * Firebase Initialization Function
 * This must be called before any firebase query
 */
function SetupFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyDTjmSeEocZPtrYIymBKgecYzLqFNMAPBo",
    authDomain: "melzo-metaverse.firebaseapp.com",
    projectId: "melzo-metaverse",
    storageBucket: "melzo-metaverse.appspot.com",
    messagingSenderId: "885342918262",
    appId: "1:885342918262:web:87331e930c8e0eef4cf157",
    measurementId: "G-796B3SZ294",
  };
  // Initialize Firebase
  firebaseOther = firebase.initializeApp(firebaseConfig, "other");
}

export default firebaseOther;
