/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import Logo from "../assets/images/logo.png";
import { Accordion, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../assets/styles/sidebar.css";
import menu from "../assets/images/menu.png";
import userlist from "../assets/images/user list.png";
import chartimg from "../assets/images/chart.png";
import datausage from "../assets/images/data.png";
import webinarimg from "../assets/images/admin-webinar.png";
import displayImg from "../assets/images/admin-display.png";
import entertainmentImg from "../assets/images/admin-entertainment.png";
export default class SideBar extends React.Component {
  OpenMenu = () => {
    var openmenu = document.getElementById("OpenMenu");
    var menu = document.getElementById("Menu");
    var overlayMenu = document.getElementById("OverlayMenu");
    openmenu.style.display = "flex";
    openmenu.classList.add("openmenuanimation");
    openmenu.classList.remove("closmenuanimation");
    overlayMenu.style.display = "flex";
  };

  CloseMenu = () => {
    var openmenu = document.getElementById("OpenMenu");
    var menu = document.getElementById("Menu");
    var overlayMenu = document.getElementById("OverlayMenu");
    openmenu.style.display = "flex";
    openmenu.classList.remove("openmenuanimation");
    openmenu.classList.add("closmenuanimation");
    overlayMenu.style.display = "none";
  };
  render() {
    return (
      <>
        <div className="sidebar-header">
          <br />
          <br />
          <div className="flexlogo">
            <img src={Logo} className="widthlogo" alt="unite_logo" />
          </div>
        </div>
        <div className="positionmenu">
          <img
            src={menu}
            alt="menu"
            id="Menu"
            onClick={this.OpenMenu}
            className="widthmenu"
          />
        </div>
        <div
          className="overlaymenu"
          id="OverlayMenu"
          onClick={this.CloseMenu}
        ></div>

        <div className="menubg openmenuanimation" id="OpenMenu">
          <ul className="flexmenu ">
            <li>
              <span className="close" onClick={this.CloseMenu} id="btn-cancel">
                &times;
              </span>
            </li>
            {/* {localStorage.getItem('user-role') === 'admin' && */}
            <li className="flexmenulist">
              <Button
                onClick={this.CloseMenu}
                as={NavLink}
                activeClassName="selected-sidebar-item"
                to="/userlist"
                className="child-menu-item-props"
              >
                <img src={userlist} className="mr-3 widthlisticon" />
                Registration
              </Button>
            </li>
            {/* // } */}
            {/* <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/Lobby" className="child-menu-item-props"><img src={datausage} className="mr-3 widthlisticon" />Lobby</Button>
                        </li> */}
            {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/WebinarHall" className="child-menu-item-props"><img src={webinarimg} className="mr-3 widthlisticon" />Webinar Hall</Button>

                            </li>
                        } */}
            {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/medexFaculty" className="child-menu-item-props"><img src={facultyImg} className="mr-3 widthlisticon" />MEDEX Faculty</Button>
                            </li>
                        } */}
            <li className="flexmenulist">
              <Button
                onClick={this.CloseMenu}
                as={NavLink}
                activeClassName="selected-sidebar-item"
                to="/analytics"
                className="child-menu-item-props"
              >
                <img src={displayImg} className="mr-3 widthlisticon" />
                Analytics
              </Button>
            </li>
            <li className="flexmenulist">
              <Button
                onClick={this.CloseMenu}
                as={NavLink}
                activeClassName="selected-sidebar-item"
                to="/leaderboard"
                className="child-menu-item-props"
              >
                <img src={entertainmentImg} className="mr-3 widthlisticon" />
                Leaderboard
              </Button>
            </li>
            <li className="flexmenulist">
              <Button
                onClick={this.CloseMenu}
                as={NavLink}
                activeClassName="selected-sidebar-item"
                to="/live"
                className="child-menu-item-props"
              >
                <img src={chartimg} className="mr-3 widthlisticon" />
                Live Users
              </Button>
            </li>
            {/* {localStorage.getItem('user-role') !== 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/barchart" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Chart</Button>
                            </li>
                        } */}
          </ul>
        </div>
        <div className="sidebar-menu">
          <br />
          <br />
          {localStorage.getItem("user-role") === "admin" && (
            <Button
              as={NavLink}
              activeClassName="selected-sidebar-item"
              to="/userlist"
              className="child-menu-item-props"
            >
              <img src={userlist} className="mr-3 widthlisticon" />
              Registration
            </Button>
          )}
          <Button
            as={NavLink}
            activeClassName="selected-sidebar-item"
            to="/userlist"
            className="child-menu-item-props"
          >
            <img src={datausage} className="mr-3 widthlisticon" />
            User List
          </Button>
          {/* {localStorage.getItem("user-role") === "admin" && (
            <Button
              as={NavLink}
              activeClassName="selected-sidebar-item"
              to="/WebinarHall"
              className="child-menu-item-props"
            >
              <img src={webinarimg} className="mr-3 widthlisticon" />
              Analytics
            </Button>
          )} */}
          {/* <Button
            as={NavLink}
            activeClassName="selected-sidebar-item"
            to="/medexFaculty"
            className="child-menu-item-props"
          >
            <img src={facultyImg} className="mr-3 widthlisticon" />
            Medex Faculty
          </Button> */}
          <Button
            as={NavLink}
            activeClassName="selected-sidebar-item"
            to="/analytics"
            className="child-menu-item-props"
          >
            <img src={chartimg} className="mr-3 widthlisticon" />
            Analytics
          </Button>
          <Button
            as={NavLink}
            activeClassName="selected-sidebar-item"
            to="/leaderboard"
            className="child-menu-item-props"
          >
            <img src={userlist} className="mr-3 widthlisticon" />
            Leaderboard
          </Button>
          <Button
            as={NavLink}
            activeClassName="selected-sidebar-item"
            to="/dashboard-charts"
            className="child-menu-item-props"
          >
            <img src={displayImg} className="mr-3 widthlisticon" />
            Charts
          </Button>

          {/* <Button
            as={NavLink}
            activeClassName="selected-sidebar-item"
            to="/break-time-zone"
            className="child-menu-item-props"
          >
            <img src={entertainmentImg} className="mr-3 widthlisticon" />
            Break time zone
          </Button>
          <Button
            as={NavLink}
            activeClassName="selected-sidebar-item"
            to="/live"
            className="child-menu-item-props"
          >
            <img src={chartimg} className="mr-3 widthlisticon" />
            Live Users
          </Button> */}
        </div>
        <div className="menu">
          <h1 className=""></h1>
        </div>
      </>
    );
  }
}
