/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from 'react';
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { MDBDataTable } from 'mdbreact';

import "react-datepicker/dist/react-datepicker.css";
import firebase from './firebase';
import { Form, FormControl, Button } from 'react-bootstrap';
import { getCurrentPageAnalytics } from './firebase-function';
import { get_users_list } from './UserImageClick'
import moment from 'moment'

let fromDate;
let toDate;
let toHHMMSS;
let typeValue;

Array.prototype.sortBy = function (p) {
    return this.slice(0).sort(function (a, b) {
        return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
    });
}

export default class ArchiveExport extends React.Component {
    static data = [];
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            dataUser: [],
            startDateFrom: '',
            startDateTO: '',
            totalvisitor: '',
            clickData: []
        }
        this.filterName = this.filterName.bind(this);
    }
    componentDidMount() {

        // let userrole = localStorage.getItem('user-role');
        // if (userrole === 'user') {
        //     this.get_total_visitor();
        // }

        get_users_list(this.getUSerData);
        // getCurrentPageAnalytics('MEDEX2022LiveUsers', this.manageMyTable);

    }
    getUSerData = (databaseEntries) => {
        this.setState({
            clickData: databaseEntries
        }, () => {
            getCurrentPageAnalytics('MEDEX2022LiveUsers', this.manageMyTable);
        })
    }

    manageMyTable = (databaseEntries) => {
        let formattedData = [];
        for (let i = 0; i < databaseEntries.length; i++) {
            let endDate;
            if (databaseEntries[i].endTime === "") {
                let addSec = databaseEntries[i].startTime + 30000;
                let addedTime = new Date(addSec).toLocaleString('en-IN');
                endDate = addedTime
            } else {
                endDate = new Date(databaseEntries[i].endTime).toLocaleString('en-IN');
            }

            let startDate;
            if (databaseEntries[i].startTime === "") {
                startDate = "-"
            } else {
                startDate = new Date(databaseEntries[i].startTime).toLocaleString('en-IN');
            }
            let mobNumber;
            if (databaseEntries[i].mobileNumber === "") {
                mobNumber = "-"
            } else {
                mobNumber = databaseEntries[i].mobileNumber
            }

            let diff = Math.abs(new Date(databaseEntries[i].endTime) - new Date(databaseEntries[i].startTime));
            let ms = diff % 1000;
            diff = (diff - ms) / 1000
            let ss = diff % 60;
            diff = (diff - ss) / 60
            let mm = diff % 60;
            diff = (diff - mm) / 60
            let hh = diff % 24;
            let days = (diff - hh) / 24

            if (hh < 10) {
                hh = '0' + hh;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            if (ss < 10) {
                ss = '0' + ss;
            }

            if (days === 0) {
                diff = hh + ":" + mm + ":" + ss;
            }
            else {
                diff = days + "/" + hh + ":" + mm + ":" + ss;
            }

            if (databaseEntries[i].endTime === "") {
                diff = "00:00:30";
            }

            let userType;
            if (diff <= "00:00:30") {
                userType = 'Regular'
            } else {
                userType = 'Active'
            }

            let CompleteData = {
                id: databaseEntries[i]._id,
                fullname: databaseEntries[i].name,
                useremail: databaseEntries[i].email,
                designation: databaseEntries[i].designation,
                speciality: databaseEntries[i].speciality,
                country: databaseEntries[i].countryName,
                mobilenumber: mobNumber,
                // type: typeValue,

                // username: databaseEntries[i].username,
                // projectid: databaseEntries[i].projectid,
                // projectname: databaseEntries[i].projectname,
                starttime: startDate,
                endtime: endDate,
                duration: diff,
                usertype: userType,
                filterTime: databaseEntries[i].startTime,
                myfilterstartdate: new Date(databaseEntries[i].startTime).toLocaleDateString()
            }


            if (databaseEntries[i].roomName === 'archive1' || databaseEntries[i].roomName === 'archive2' ||
                databaseEntries[i].roomName === 'archive3' || databaseEntries[i].roomName === 'archive4' ||
                databaseEntries[i].roomName === 'archive5' || databaseEntries[i].roomName === 'archive6' ||
                databaseEntries[i].roomName === 'archive7' || databaseEntries[i].roomName === 'archive8' ||
                databaseEntries[i].roomName === 'archive9' || databaseEntries[i].roomName === 'archive10' ||
                databaseEntries[i].roomName === 'archive11') {

                if (databaseEntries[i].email !== 'taycoon6@hotmail.com'
                    && databaseEntries[i].email !== 'kathandjv@gmail.com'
                    && databaseEntries[i].email !== 'jigar@smart-sonix.com'
                    && databaseEntries[i].email !== 'ritikadesai14@gmail.com'
                    && databaseEntries[i].email !== 'mariam.ouda@boehringer-ingelheim.com'
                    && days === 0
                ) {

                    formattedData.push(CompleteData)
                }
            }
        }
        this.formatTable(formattedData);
    }

    formatTable = (myArrayData) => {
        var sortArray = myArrayData.sort(function (a, b) { return b.filterTime - a.filterTime });
        let mydata = {
            columns: [
                {
                    label: 'User Name',
                    field: 'fullname',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Email',
                    field: 'useremail',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Country',
                    field: 'countryName',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Mobile Number',
                    field: 'mobilenumber',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Designation',
                    field: 'designation',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Speciality',
                    field: 'speciality',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Room',
                    field: 'room',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Session',
                    field: 'link',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Start Time',
                    field: 'startTime',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Duration',
                    field: 'duration',
                    sort: 'asc',
                    width: 150
                },
            ],
            rows: sortArray,
        };
        this.setState({ data: mydata, data2: mydata });
    }

    findUnique = (arr, predicate) => {
        var found = {};
        arr.forEach(d => {
            found[predicate(d)] = d;
        });
        return Object.keys(found).map(key => found[key]);
    }

    filterName(event) {
        // const tempData = this.filterByValue(this.state.data.rows, event.target.value);

        const tempData = this.filterByValue(this.state.data.rows, event.target.value);
        const tempClickData = this.filterByValue(this.state.clickData.rows, event.target.value);

        let sortArray = tempData.sortBy('email')

        var result = [];

        let finalData = []


        let count = 0;
        let roomName = ""
        let demoData = tempClickData.reduce(function (res, value) {
            if (!res[value.email]) {
                roomName = ""
                count = 0
                res[value.email] = {
                    email: value.email,
                };
                finalData.push(res[value.email])
            }
            if (value.link != "Microsite" && value.projectname != "Display Gallery") {
                count++
                roomName = roomName + (count == 1 ? "" : ", ") + count + ")" + value.projectname + " - " + value.link
            }
            res[value.email].roomName = roomName
            return res;
        }, []);

        let finalDemoData = []
        for (var key in demoData) {
            finalDemoData.push(demoData[key]);
        }

        // console.log("finalDemoData :: ", finalDemoData)

        let tmpcount = 0
        sortArray.map((obj) => {
            let tmpArray = {}

            if (result.filter((filterData) => { return filterData.useremail == obj.useremail }).length === 0) {
                tmpcount++
                let demo1 = finalDemoData.filter((filterData) => { return filterData.email == obj.useremail })
                console.log(demo1)
                tmpArray.srNo = tmpcount
                tmpArray.tmpcount = tmpcount
                tmpArray.designation = obj.designation
                tmpArray.speciality = obj.speciality
                tmpArray.useremail = obj.useremail
                tmpArray.fullname = obj.fullname
                tmpArray.countryName = obj.country
                tmpArray.mobilenumber = obj.mobilenumber
                tmpArray.starttime = obj.starttime
                tmpArray.endtime = obj.endtime
                tmpArray.duration = obj.duration
                tmpArray.room = obj.room
                tmpArray.link = obj.link
                if (demo1.length > 0) {
                    console.log(demo1[0].roomName)
                    tmpArray.finalRoomName = demo1[0].roomName
                }
                else {
                    tmpArray.finalRoomName = ""
                }
            }
            else {
                tmpArray.tmpcount = tmpcount
                tmpArray.designation = ""
                tmpArray.speciality = ""
                tmpArray.useremail = ""
                tmpArray.duration = ""
                tmpArray.fullname = ""
                tmpArray.countryName = ""
                tmpArray.mobilenumber = ""
                tmpArray.room = obj.room
                tmpArray.link = obj.link
                tmpArray.starttime = obj.starttime
                tmpArray.endtime = obj.endtime
                tmpArray.duration = obj.duration
                tmpArray.finalRoomName = ""
            }
            tmpArray.totalDuration = ""
            result.push(tmpArray)
        })

        let tcount = 0
        result.map((obj) => {
            if (tcount != obj.tmpcount && obj.useremail != "") {
                tcount = obj.tmpcount
                let filterdata = result.filter((tmpobj) => { return (tmpobj.tmpcount == obj.tmpcount) })
                var d = '0:0:0';
                filterdata.map((tmpobj) => {
                    d = moment.duration(d).add(moment.duration(tmpobj.duration))
                })
                obj.totalDuration = moment.utc(d.as('milliseconds')).format("HH:mm:ss")
            }
        })

        let mydata = {
            columns: this.state.data.columns,
            rows: result
        }
        this.setState({
            data2: mydata
        });



        // let mydata = {
        //     columns: this.state.data.columns,
        //     rows: tempData
        // }
        // this.setState({
        //     data2: mydata
        // });
    }

    filterByValue(array, value) {
        return array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    handleChange = date => {
        this.setState({
            startDateFrom: date
        });
    };

    handleChangeTo = date => {
        this.setState({
            startDateTO: date
        });
    };

    handleFilter = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value;
        toDate = document.getElementById('toDate').value;
        if (fromDate === "") {
            alert("Please enter From date")
        } else if (toDate === "") {
            alert("Please enter To date")
        } else {
            const tempData = this.filterByValueDate(this.state.data);
            this.setState({
                data2: tempData
            });

        }
    }

    filterByValueDate(array) {

        return array.filter((item) =>

            item.starttime >= fromDate && item.starttime <= toDate
        );

    }

    handleCancle = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value = "";
        toDate = document.getElementById('toDate').value = "";
        this.setState({
            startDateFrom: '',
            startDateTO: ''
        })
        this.componentDidMount()
    }

    firstDate = (e) => {
        e.preventDefault();
        let tmpdate = '26/1/2021'
        const tempData = this.filterByValue(this.state.data.rows, tmpdate);
        const tempClickData = this.filterByValue(this.state.clickData.rows, tmpdate);

        let sortArray = tempData.sortBy('email')

        var result = [];

        let finalData = []


        let count = 0;
        let roomName = ""
        let demoData = tempClickData.reduce(function (res, value) {
            if (!res[value.email]) {
                roomName = ""
                count = 0
                res[value.email] = {
                    email: value.email,
                };
                finalData.push(res[value.email])
            }
            if (value.link != "Microsite" && value.projectname != "Display Gallery") {
                count++
                roomName = roomName + (count == 1 ? "" : ", ") + count + ")" + value.projectname + " - " + value.link
            }
            res[value.email].roomName = roomName
            return res;
        }, []);

        let finalDemoData = []
        for (var key in demoData) {
            finalDemoData.push(demoData[key]);
        }

        let tmpcount = 0
        sortArray.map((obj) => {
            let tmpArray = {}

            if (result.filter((filterData) => { return filterData.useremail == obj.useremail }).length === 0) {
                tmpcount++
                let demo1 = finalDemoData.filter((filterData) => { return filterData.email == obj.useremail })
                tmpArray.srNo = tmpcount
                tmpArray.tmpcount = tmpcount
                tmpArray.designation = obj.designation
                tmpArray.speciality = obj.speciality
                tmpArray.useremail = obj.useremail
                tmpArray.fullname = obj.fullname
                tmpArray.countryName = obj.country
                tmpArray.mobilenumber = obj.mobilenumber
                tmpArray.starttime = obj.starttime
                tmpArray.endtime = obj.endtime
                tmpArray.duration = obj.duration
                tmpArray.room = obj.room
                tmpArray.link = obj.link
                if (demo1.length > 0)
                    tmpArray.finalRoomName = demo1[0].roomName
                else
                    tmpArray.finalRoomName = ""
            }
            else {
                tmpArray.tmpcount = tmpcount
                tmpArray.designation = ""
                tmpArray.speciality = ""
                tmpArray.useremail = ""
                tmpArray.duration = ""
                tmpArray.fullname = ""
                tmpArray.countryName = ""
                tmpArray.mobilenumber = ""
                tmpArray.room = obj.room
                tmpArray.link = obj.link
                tmpArray.starttime = obj.starttime
                tmpArray.endtime = obj.endtime
                tmpArray.duration = obj.duration
                tmpArray.finalRoomName = ""
            }
            tmpArray.totalDuration = ""
            result.push(tmpArray)
        })

        let tcount = 0
        result.map((obj) => {
            if (tcount != obj.tmpcount && obj.useremail != "") {
                tcount = obj.tmpcount
                let filterdata = result.filter((tmpobj) => { return (tmpobj.tmpcount == obj.tmpcount) })
                var d = '0:0:0';
                filterdata.map((tmpobj) => {
                    d = moment.duration(d).add(moment.duration(tmpobj.duration))
                })
                obj.totalDuration = moment.utc(d.as('milliseconds')).format("HH:mm:ss")
            }
        })

        let mydata = {
            columns: this.state.data.columns,
            rows: result
        }
        this.setState({
            data2: mydata
        });

    }

    render() {
        return (
            <div>
                <div className="flexheading">
                    <h3 className="fontweight fontfamily">Archive Export</h3>
                    <div className="flexheading">
                        <FormControl type="text" placeholder="search" onChange={(e) => this.filterName(e)} className="mr-sm-2 navbar-search-prop" />

                    </div>
                </div>
                <div class="schedule-tab">
                    <div class="schedule-tab__item " data-tab="tab-1" onClick={this.firstDate} >
                        <h2>01</h2>
                        <p>22/01/21</p>
                    </div>
                </div>
                <div className="flexheading">

                    <Form inline className=" marginbottomform">
                        <div>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button  downloadbtn"
                                table="table-to-xls"
                                filename="MEDEX 2022 Archive Gallery Visit"
                                sheet="MEDEX 2022 Archive Gallery Visit"
                                buttonText="Download as XLS" />
                        </div>
                    </Form>
                </div>
                <div className="overflowtableanalysis">

                    <MDBDataTable
                        searching={false}
                        paging={true}
                        data={this.state.data2}
                    />

                </div>
                <div className="overflowtableanalysis" style={{ display: 'none' }}>
                    <table id="table-to-xls" className="table table-hover margintoptable">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Country</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">Email</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Speciality</th>
                                <th scope="col">Topics Visited</th>
                                <th scope="col">Start Time</th>
                                <th scope="col">End Time</th>
                                <th scope="col">Duration</th>
                                <th scope="col">Total Duration</th>

                            </tr>
                        </thead>
                        {this.state.data2.rows &&
                            <tbody>
                                {Object.values(this.state.data2.rows).map((users, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{users.srNo}</td>
                                            <td>{users.fullname}</td>
                                            <td>{users.countryName}</td>
                                            <td>{users.mobilenumber}</td>
                                            <td>{users.useremail}</td>
                                            <td>{users.designation}</td>
                                            <td>{users.speciality}</td>
                                            <td>{users.finalRoomName}</td>
                                            <td>{users.starttime}</td>
                                            <td>{users.endtime}</td>
                                            <td>{users.duration}</td>
                                            <td>{users.totalDuration}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        )
    }
}