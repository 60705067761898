/* eslint-disable no-unused-vars */
import React from "react";
import SideBar from "./components/sidebar";
import Dashboard from "./Dashboard";
import { BrowserRouter as Router } from "react-router-dom";
import firebase from "./firebase";
import { AuthConsumer, AuthContext } from "./AuthContext";
import App from "./App";
let userId;
let checkoutId;
let currentComponent;

class MainDash extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      usernameLocal: "",
    };
    // this.Clicklogout = this.Clicklogout.bind(this);
  }
  componentDidMount() {
    // checkoutId = /[^/]*$/.exec(window.location.href)[0];
    // currentComponent = this;
    // localStorage.setItem('checkoutId', checkoutId);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.context.login(true);
        // const ref = firebase.database().ref("users/" + user.uid);
        // ref.once('value', (snapshot) => {
        //   const dbUser = snapshot.val();
        //   if (dbUser) {
        //     if(dbUser.userRole==="SUPER_ADMIN"){
        //         userId = user.uid
        //         localStorage.setItem('userUid', userId);
        //         this.context.login(true);
        //   }
        //   else{
        //     alert("You are not authorized to access this website.")
        //   }
        //   }
        // });
      } else {
        // this.context.logout(false);
      }
    });
  }

  render() {
    return (
      // <AuthConsumer>
      //     {({ isUserLoggedIn }) =>
      //         (
      //             isUserLoggedIn ?
      //                 (
      <Router>
        <div className="row heightrow">
          <div className="col-lg-2 paddingright">
            <div className="sidebar-props ">
              <SideBar />
            </div>
          </div>
          <div className="col-md-12 col-lg-10 dashboard-props">
            <Dashboard />
          </div>
        </div>
      </Router>
      //                 ) :
      //                 (
      //                     <App />
      //                 )
      //         )
      //     }
      // </AuthConsumer>
    );
  }
}
export default MainDash;
