/* eslint-disable no-unused-vars */
import React from 'react';
// import firebase from './firebase';
// import { Chart } from 'react-charts'
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList,
} from 'recharts';
import firebase from './firebase';

import '../src/style.css';

// let dataSource;
// let demoarray = [];

// 1) total number of visiter
// 2) 500 whatsapp
// 3) download 
// 4) phone 
// 5) microsite
// 6) 

let data;
let demoarray = [];
let subdemoarray = [];
const micrositeLinks = ['http://bit.ly/VR-AjmeraiLand',
  'https://bit.ly/Ajmera_Exotica_VR',
  'https://www.youtube.com/watch?v=L5-uVwj0LjQ&feature=youtu.be',
  'https://youtu.be/OaUCULxWi0g',
  'http://digiport.housing.com/digitour/projects/herohomes/herohomes.html',
  'https://my.matterport.com/show/?m=gRBr6J44ZSU',
  'https://my.matterport.com/show/?m=LhWSgpGoNZ4&brand=0',
  'https://www.palava.in/virtual-tour/',
  'https://youtu.be/1PvCqWSTw4Q',
  'http://tiny.cc/TE_ATR_V30',
  'http://tiny.cc/TMFT_exphome',
  'https://bit.ly/3dsG3fP',
  'https://bit.ly/306zw6x',
  'https://bit.ly/RG-vt'];
const renderCustomizedLabel = (props) => {
  const {
    x, y, width, height, value,
  } = props;
  const radius = 10;

  return (
    <g>
      {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
      <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
        {value.split(' ')[1]}
      </text>
    </g>
  );
};


class ChartBar extends React.Component {
  static data = [];
  static subdata = [];
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/a5Leskck/';
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data2: [],
      subdata: []
      // temp: [],

    }

  }

  componentDidMount() {

    this.get_users_list();
  }

  get_users_list() {
    // e.preventDefault();
    let currentComponent = this;

    var result = [];
    let userPid = localStorage.getItem('project-id');

    var firestore = firebase.firestore();
    //------------------------PROJECT VISITED--------------------------

    let items = firestore.collection("ExpoprojectVisitLogs")
    items = items.where("projectid", "==", userPid)
    // items = items.where("starttime", ">=", 1596774600000)
    items = items.where("expo", "==", "pinclick")
    // const items = firestore.collection("ExpoprojectVisitLogs");
    let data = [];

    let ProjectVisitData = new Promise((resolve, reject) => {
      items.get().then(function (itemSnapshot) {
        itemSnapshot.forEach(function (docItem) {
          if ((docItem.data().mobilenumber !== '9375705497')
            && (docItem.data().mobilenumber !== '9979981349')
            && (docItem.data().mobilenumber !== '+917984604061')
            && (docItem.data().mobilenumber !== '9016641369')
            && (docItem.data().mobilenumber !== '9924302720')
            && (docItem.data().mobilenumber !== '7383810472')
            && (docItem.data().mobilenumber !== '1234567891')
            && (docItem.data().mobilenumber !== '1234568791')
            && (docItem.data().mobilenumber !== '7048853622')
            && (docItem.data().mobilenumber !== '9662552666')
            && (docItem.data().mobilenumber !== '7621901935')
            && (docItem.data().mobilenumber !== '7984604061')
            && (docItem.data().mobilenumber !== '9664777053')
            && (docItem.data().mobilenumber !== '9016037231')
          ) {
            var docData = docItem.data();
            docData._id = docItem.id;

            // console.log("DOCDATA DEM :: ",docData)
            let myPname;
            if (docData.projectname === '') {
              myPname = 0
            } else {
              myPname = docData.projectname
            }

            let myPid;
            if (docData.projectid === '') {
              myPid = 0
            } else {
              myPid = docData.projectid
            }

            let mydate;
            if (docData.starttime === "") {
              mydate = '-'
            } else {
              // mydate = new Date(docData.date).toLocaleDateString()
              mydate = new Date(docData.starttime).toLocaleString('en-IN')
            }

            if (docData.mobilenumber !== 'undefined' && docData.username !== 'undefined' && docData.mobilenumber !== 'null' && docData.username !== 'null') {
              demoarray.push({
                mobilenumber: docData.mobilenumber,
                username: docData.username,
                projectName: myPname,
                projectId: myPid,
                date: mydate,
                starttime: docData.starttime,
              });
            }
          }
        })
        resolve('Done');
      }).catch(error => {
        reject(error);
      })
    });

    ProjectVisitData.then(projectResult => {

      // currentComponent.setState({ data, data2: demoarray });

      demoarray.reduce(function (res, value) {
        if (!res[value.mobilenumber]) {
          res[value.mobilenumber] = {
            mobilenumber: value.mobilenumber,
            username: value.username,
            projectName: value.projectName,
            projectId: value.projectId,
            date: value.date,
            starttime: 0
          };
          result.push(res[value.mobilenumber])
        }
        res[value.mobilenumber].starttime += value.starttime;
        return res;
      }, {});

    }).catch(error => {
      console.error('Project visit chart data Error', error);
    })
    // console.log("DOCDATA :: ",demoarray.length)

    let subBar = firestore.collection("ExpoimageClick")
    subBar = subBar.where("projectid", "==", userPid)
    // subBar = subBar.where("date", ">=", 1596774600000)
    subBar = subBar.where("expo", "==", "pinclick")
    let subdata = [];

    let ImageClickData = new Promise((resolve, reject) => {
      subBar.get().then(function (itemSnapshot) {
        itemSnapshot.forEach(function (docItem) {
          var docData = docItem.data();
          // console.log("DOC DATA :: ",docData);
          docData._id = docItem.id;
          // console.log("DOC DATA ID :: ",docData.currenttime);


          let mobNumber;
          if (docData.mobilenumber === "") {
            mobNumber = "-"
          } else {
            mobNumber = docData.mobilenumber
          }


          let mydate;
          if (docData.date === "") {
            mydate = '-'
          } else {
            // mydate = new Date(docData.date).toLocaleDateString()
            mydate = new Date(docData.date).toLocaleString('en-IN')
          }

          var value = docData.link.split('/')[2];
          let myLink;
          if (value === undefined) {

            if (docData.link === 'melzounite') {
              myLink = 'Video call'
            } else if (/^\d{10}$/.test(docData.link)) {
              myLink = 'Audio call'
            }

          } else {

            if (value === 'drive.google.com') {
              myLink = 'Download'
            } else if (value === 'wa.me') {
              myLink = 'Whatsapp'
            } else {
              myLink = 'Microsite'

            }

            // else if (value === 'expo.virtualpropexpo2020.com') {
            //   var micro = docData.link.split('/')[3];
            //   // console.log("Micro :: ",micro)
            //   myLink = 'Microsite-' + micro
            // }

          }


          // console.log("Converted :: ",myLink)

          if (docData.mobilenumber !== 'undefined' && docData.username !== 'undefined' && docData.mobilenumber !== 'null' && docData.username !== 'null') {

            let CompleteData = {
              id: docItem.id,
              mobilenumber: mobNumber,
              username: docData.username,
              projectid: docData.projectid,
              projectname: docData.projectname,
              link: myLink,
              date: mydate
            }
            subdemoarray.push(CompleteData)
          }
        })
        resolve('Done');
      }).catch(error => {
        reject(error);
      })
    });
    // console.log("DATA ARRAY :: ", subdemoarray)
    ImageClickData.then(ImageClickResult => {

      var day1Array = subdemoarray.filter(function (sub) {
        var datesplit = sub.date.split(',');
        return datesplit[0] === '15/8/2020'
      });
      // console.log("Day1 :: ",day1Array)

      var Day1filterWhatsapp = day1Array.filter(function (sub) {
        return sub.link === "Whatsapp";
      });

      var Day1filterDownload = day1Array.filter(function (sub) {
        return sub.link === "Download";
      });

      var Day1filterVideocall = day1Array.filter(function (sub) {
        return sub.link === "Video call";
      });

      var Day1filterAudiocall = day1Array.filter(function (sub) {
        return sub.link === "Audio call";
      });

      var Day1filterMicrosite = day1Array.filter(function (sub) {
        var micro = sub.link.split('-');
        return micro[0] === "Microsite"

        // return var micro = sub.link;
        // return console.log("micro :: ",sub.link.split('-'))
        // return sub.link === "Audio call";
      });

      var day1demoArray = result.filter(function (sub) {
        var datesplit = sub.date.split(',');
        return datesplit[0] === '15/8/2020'
      });

      console.log("DAY1 DOCDATA :: ", day1demoArray)
      console.log("DAY1 whatsapp :: ", Day1filterWhatsapp.length)
      console.log("DAY1 download :: ", Day1filterDownload.length)
      console.log("DAY1 Videocall :: ", Day1filterVideocall.length)
      console.log("DAY1 Audiocall :: ", Day1filterAudiocall.length)
      console.log("DAY1 Microsite :: ", Day1filterMicrosite.length)



      var day2Array = subdemoarray.filter(function (sub) {
        var datesplit = sub.date.split(',');
        return datesplit[0] === '16/8/2020'
      });
      // console.log("Day2 :: ",day2Array)

      var Day2filterWhatsapp = day2Array.filter(function (sub) {
        return sub.link === "Whatsapp";
      });

      var Day2filterDownload = day2Array.filter(function (sub) {
        return sub.link === "Download";
      });

      var Day2filterVideocall = day2Array.filter(function (sub) {
        return sub.link === "Video call";
      });

      var Day2filterAudiocall = day2Array.filter(function (sub) {
        return sub.link === "Audio call";
      });

      var Day2filterMicrosite = day2Array.filter(function (sub) {
        var micro = sub.link.split('-');
        return micro[0] === "Microsite"

        // return var micro = sub.link;
        // return console.log("micro :: ",sub.link.split('-'))
        // return sub.link === "Audio call";
      });
      var day2demoArray = result.filter(function (sub) {
        var datesplit = sub.date.split(',');
        return datesplit[0] === '16/8/2020'
      });

      console.log("DAY2 DOCDATA :: ", day2demoArray.length)
      console.log("DAY2 whatsapp :: ", Day2filterWhatsapp.length)
      console.log("DAY2 download :: ", Day2filterDownload.length)
      console.log("DAY2 Videocall :: ", Day2filterVideocall.length)
      console.log("DAY2 Audiocall :: ", Day2filterAudiocall.length)
      console.log("DAY2 Microsite :: ", Day2filterMicrosite.length)



      // var day3Array = subdemoarray.filter(function(sub) {
      //   var datesplit =  sub.date.split(',');
      //   return datesplit[0] === '17/8/2020'
      // });
      // console.log("Day3 :: ",day3Array)

      // var Day3filterWhatsapp =  day3Array.filter(function(sub) {
      //   return sub.link === "Whatsapp";
      // });

      // var Day3filterDownload =  day3Array.filter(function(sub) {
      //   return sub.link === "Download";
      // });

      // var Day3filterVideocall =  day3Array.filter(function(sub) {
      //   return sub.link === "Video call";
      // });

      // var Day3filterAudiocall =  day3Array.filter(function(sub) {
      //   return sub.link === "Audio call";
      // });

      // var Day3filterMicrosite =  day3Array.filter(function(sub) {
      //   var micro =  sub.link.split('-');
      //   return micro[0] === "Microsite" 

      //   // return var micro = sub.link;
      //   // return console.log("micro :: ",sub.link.split('-'))
      //   // return sub.link === "Audio call";
      // });

      // var day3demoArray = result.filter(function(sub) {
      //   var datesplit =  sub.date.split(',');
      //   return datesplit[0] === '9/8/2020'
      // });
      // console.log("DAY3 DOCDATA :: ",day3demoArray.length)
      // console.log("DAY3 whatsapp :: ",Day3filterWhatsapp.length)
      // console.log("DAY3 download :: ",Day3filterDownload.length)
      // console.log("DAY3 Videocall :: ",Day3filterVideocall.length)
      // console.log("DAY3 Audiocall :: ",Day3filterAudiocall.length)
      // console.log("DAY3 Microsite :: ",Day3filterMicrosite.length)


      // var day4Array = subdemoarray.filter(function(sub) {
      //   var datesplit =  sub.date.split(',');
      //   return datesplit[0] === '10/8/2020'
      // });
      // console.log("Day4 :: ",day4Array)

      // var Day4filterWhatsapp =  day4Array.filter(function(sub) {
      //   return sub.link === "Whatsapp";
      // });

      // var Day4filterDownload =  day4Array.filter(function(sub) {
      //   return sub.link === "Download";
      // });

      // var Day4filterVideocall =  day4Array.filter(function(sub) {
      //   return sub.link === "Video call";
      // });

      // var Day4filterAudiocall =  day4Array.filter(function(sub) {
      //   return sub.link === "Audio call";
      // });

      // var Day4filterMicrosite =  day4Array.filter(function(sub) {
      //   var micro =  sub.link.split('-');
      //   return micro[0] === "Microsite" 

      //   // return var micro = sub.link;
      //   // return console.log("micro :: ",sub.link.split('-'))
      //   // return sub.link === "Audio call";
      // });

      // var day4demoArray = result.filter(function(sub) {
      //   var datesplit =  sub.date.split(',');
      //   return datesplit[0] === '10/8/2020'
      // });
      // console.log("DAY4 DOCDATA :: ",day4demoArray.length)
      // console.log("DAY4 whatsapp :: ",Day4filterWhatsapp.length)
      // console.log("DAY4 download :: ",Day4filterDownload.length)
      // console.log("DAY4 Videocall :: ",Day4filterVideocall.length)
      // console.log("DAY4 Audiocall :: ",Day4filterAudiocall.length)
      // console.log("DAY4 Microsite :: ",Day4filterMicrosite.length)

      // currentComponent.setState({ subdata : subdemoarray });

      const ptotal = day1demoArray.length + day2demoArray.length;
      const totalwhatsapp = Day1filterWhatsapp.length + Day2filterWhatsapp.length;
      const totalDownload = Day1filterDownload.length + Day2filterDownload.length;
      const totalVideocall = Day1filterVideocall.length + Day2filterVideocall.length;
      const totalAudiocall = Day1filterAudiocall.length + Day2filterAudiocall.length;
      const totalMicrosite = Day1filterMicrosite.length + Day2filterMicrosite.length;




      data = [
        {
          name: 'Total',
          Visitor: ptotal,
          Whatsapp: totalwhatsapp,
          Download: totalDownload,
          Videocall: totalVideocall,
          Audiocall: totalAudiocall,
          Microsite: totalMicrosite,
        },
        {
          name: '15/8/2020',
          Visitor: day1demoArray.length,
          Whatsapp: Day1filterWhatsapp.length,
          Download: Day1filterDownload.length,
          Videocall: Day1filterVideocall.length,
          Audiocall: Day1filterAudiocall.length,
          Microsite: Day1filterMicrosite.length,
        },
        {
          name: '16/8/2020',
          Visitor: day2demoArray.length,
          Whatsapp: Day2filterWhatsapp.length,
          Download: Day2filterDownload.length,
          Videocall: Day2filterVideocall.length,
          Audiocall: Day2filterAudiocall.length,
          Microsite: Day2filterMicrosite.length,
        },
        // {
        //   name: '9/8/2020', 
        //   Visitor: day3demoArray.length, 
        //   Whatsapp: Day3filterWhatsapp.length, 
        //   Download: Day3filterDownload.length,
        //   Videocall: Day3filterVideocall.length,
        //   Audiocall: Day3filterAudiocall.length,
        //   Microsite: Day3filterMicrosite.length,
        // },
        // {
        //   name: '10/8/2020', 
        //   Visitor: day4demoArray.length, 
        //   Whatsapp: Day4filterWhatsapp.length, 
        //   Download: Day4filterDownload.length,
        //   Videocall: Day4filterVideocall.length,
        //   Audiocall: Day4filterAudiocall.length,
        //   Microsite: Day4filterMicrosite.length,
        // },
        // {
        //   name: 'Page E', uv: 18, pv: 4800, amt: 2181,
        // },
        // {
        //   name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
        // },
        // {
        //   name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
        // },
      ];

      currentComponent.setState({ temp: data });

    }).catch(error => {
      console.error('Chart Error', error);
    })

    // -----------------------------------------------------------------------

    // data = [
    //   {
    //     name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    //   },
    //   {
    //     name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    //   },
    //   {
    //     name: 'Page C', uv: 2000, pv: 8, amt: 2290,
    //   },
    //   {
    //     name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    //   },
    //   {
    //     name: 'Page E', uv: 18, pv: 4800, amt: 2181,
    //   },
    //   {
    //     name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    //   },
    //   {
    //     name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    //   },
    // ];

    // currentComponent.setState({ temp: data });






  }

  render() {

    return (
      <div>
        <div className="container-fluid">
          <div className="row marginchart">
            <div className="col-12 col-md-6 col-lg-6 marginchartcol" style={{ width: '400px', height: '300px' }}>
              <BarChart
                // width={1000}
                // height={350}
                data={this.state.temp}
                // data={data}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Visitor" fill="#CD6057" minPointSize={5} >
                  <LabelList dataKey="name" content={renderCustomizedLabel} />
                </Bar>
                <Bar dataKey="Whatsapp" fill="#AA6E9E" minPointSize={10} />
                <Bar dataKey="Download" fill="#3E8AA9" minPointSize={10} />
                <Bar dataKey="Videocall" fill="#2E916F" minPointSize={10} />
                <Bar dataKey="Audiocall" fill="#828637" minPointSize={10} />
                <Bar dataKey="Microsite" fill="#B96F49" minPointSize={10} />
                {/* <Bar dataKey="pv" fill="#8884d8" minPointSize={5} >
                              <LabelList dataKey="name" content={renderCustomizedLabel} />
                            </Bar>
                            <Bar dataKey="uv" fill="#82ca9d" minPointSize={10} />
                            <Bar dataKey="amt" fill="#000000" minPointSize={10} /> */}
              </BarChart>
            </div>

          </div>
        </div>
      </div>
    );
  }
}


export default ChartBar;
